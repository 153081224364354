import { BrowserRouter } from 'react-router-dom';
import { ToastProvider as NotificationsProvider } from 'react-toast-notifications';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { UserTypeProvider } from './userType';
import { CampaignProvider } from './campaign';

interface AppProviderProps {
    children: JSX.Element | JSX.Element[];
}

export const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
    return (
        <BrowserRouter>
            <NotificationsProvider>
                <ToastProvider>
                    <UserTypeProvider>
                        <CampaignProvider>
                            <AuthProvider>{children}</AuthProvider>
                        </CampaignProvider>
                    </UserTypeProvider>
                </ToastProvider>
            </NotificationsProvider>
        </BrowserRouter>
    );
};
