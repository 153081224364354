import { useCallback, useEffect, useState } from 'react';
import { Input } from 'components/Input';
import { useHistory } from 'react-router-dom';
import { signUpValidator } from 'validators/signUp';
import getValidationErrors from 'utils/getValidationErrors';
import { useToast } from 'hooks/toast';
import * as Yup from 'yup';
import api from 'services/api';
import logo from 'assets/images/img/logo_verde.png';
import logoFooter from 'assets/images/img/logo_fundo_verde.png';
import userIcon from 'assets/images/icons/user_circle.svg';
import { Button } from 'components/Button';

import axios from 'axios';
import {
    Container,
    ContainerButtonSave,
    ContainerInput,
    ContainerInputs,
    Header,
    HeaderLogin,
    Image,
    Footer,
} from './styles';

export function SignUp(): JSX.Element {
    const history = useHistory();
    const { addError, addSuccess } = useToast();
    const [errors, setErrors] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nick, setNick] = useState('');
    const [telephone, setTelephone] = useState('');
    const [document, setDocument] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [documentMask, setDocumentMask] = useState('999.999.999-99');
    const telephoneMask = '(99) 99999-9999';

    useEffect(() => {
        const rawDocument = document.replace(/_/g, '');
        setDocumentMask(rawDocument.length <= 14 ? '999.999.999-999' : '99.999.999/9999-99');
    }, [document]);

    const handleFormatDocument = useCallback(() => {
        const documentFormated = document.replace(/\.|-|_/g, '');
        return documentFormated.replaceAll('/', '');
    }, [document]);

    const handleSignUp = useCallback(async () => {
        try {
            setErrors({});

            await signUpValidator.validate(
                { name, email, nick, telephone, document, password, confirmPassword },
                { abortEarly: false }
            );

            await api.post('users/organizer', {
                name,
                nickname: nick,
                document: handleFormatDocument(),
                email,
                password,
                phone: telephone,
            });
            addSuccess('Cadastro realizado com sucesso');
            addSuccess('Por favor, faça o login na sua nova conta');
            history.push('login');
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
                return;
            }
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 403) addError('Esse email já está sendo utilizado');
                else if (err.response?.data?.error?.message && err.response?.data?.error?.message !== 'ValidationError')
                    addError(err.response?.data?.error?.message);
                else addError('Ocorreu um erro ao cadastrar o usuário');
            }
        }
    }, [
        name,
        password,
        email,
        confirmPassword,
        nick,
        document,
        telephone,
        addError,
        addSuccess,
        history,
        handleFormatDocument,
    ]);

    return (
        <Container>
            <Header>
                <img src={logo} alt="iRifei" />
            </Header>
            <HeaderLogin>
                <Image src={userIcon} alt="" />
                <div className="text">
                    <h1>Espaço do vendedor</h1>
                    <ul>
                        <li>Utilize seus dados para fazer um cadastro na plataforma e gerenciar suas vendas</li>
                    </ul>
                </div>
            </HeaderLogin>
            <ContainerInputs>
                <ContainerInput>
                    <Input
                        label="Nome completo"
                        name="name"
                        placeholder="Nome completo"
                        value={name}
                        errors={errors}
                        onChange={e => setName(e.target.value)}
                    />
                </ContainerInput>
                <ContainerInput>
                    <Input
                        label="Apelido"
                        name="nick"
                        placeholder="Como gostaria de ser chamado?"
                        value={nick}
                        errors={errors}
                        onChange={e => setNick(e.target.value)}
                    />
                </ContainerInput>
                <ContainerInput>
                    <Input
                        label="Email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        errors={errors}
                        onChange={e => setEmail(e.target.value)}
                    />
                </ContainerInput>
                <ContainerInput>
                    <Input
                        label="CPF ou CNPJ"
                        name="document"
                        placeholder="CPF/CNPJ"
                        value={document}
                        errors={errors}
                        type="masked"
                        mask={documentMask}
                        onChange={e => setDocument(e.target.value)}
                    />
                </ContainerInput>
                <ContainerInput>
                    <Input
                        label="Telefone"
                        name="telephone"
                        placeholder="Telefone"
                        value={telephone}
                        errors={errors}
                        type="masked"
                        mask={telephoneMask}
                        onChange={e => setTelephone(e.target.value)}
                    />
                </ContainerInput>
                <ContainerInput>
                    <Input
                        label="Senha"
                        name="password"
                        placeholder="Senha"
                        value={password}
                        type="password"
                        errors={errors}
                        onChange={e => setPassword(e.target.value)}
                    />
                </ContainerInput>
                <ContainerInput>
                    <Input
                        label="Confirme a senha"
                        name="confirmPassword"
                        placeholder="Confirmar a senha"
                        value={confirmPassword}
                        type="password"
                        errors={errors}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                </ContainerInput>
                <ContainerButtonSave>
                    <Button color="primary" onClick={() => history.goBack()} icon="back">
                        Voltar
                    </Button>
                    <Button color="primary" onClick={() => handleSignUp()} icon="send">
                        Salvar
                    </Button>
                </ContainerButtonSave>
            </ContainerInputs>
            <Footer>
                <img src={logoFooter} alt="iRifei" />
                <span>Hoje pode ser seu dia de sorte</span>
            </Footer>
        </Container>
    );
}
