import styled from 'styled-components';

import { DefaultPage } from '../../_DefaultPage';

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h1`
    margin-bottom: 2rem;
`;

export const ContainerInputs = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div.container-input {
        width: 45%;
        margin-bottom: 1.5rem;
        display: flex;
        min-width: 20rem;

        input.checkbox {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1rem;
        }

        label {
            font-size: 1.4rem;
            cursor: pointer;
        }
    }
`;

export const ContainerButton = styled.div`
    width: 100%;
    margin: 3rem 0;
    display: flex;
    justify-content: flex-end;
`;
