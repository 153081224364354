import styled from 'styled-components';

import { BsChatSquareQuoteFill } from 'react-icons/bs';
import { DefaultPage } from '../../_NewDefaultPage';

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 24rem);

    h1 {
        margin-bottom: 1rem;
    }

    @media (max-width: 600px) {
        margin-top: 2rem;
    }
`;

export const Form = styled.div`
    width: 40rem;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    overflow-y: auto;

    @media (max-width: 600px) {
        width: 30rem;
    }

    @media (max-width: 450px) {
        width: 25rem;
    }

    @media (max-width: 370px) {
        width: 20rem;
    }

    input {
        margin-bottom: 1rem;
    }

    h4 {
        margin-bottom: 1rem;
    }
`;

export const TextArea = styled.textarea`
    width: 100%;
    min-height: 10rem;
    border-radius: 18px;
    background-color: var(--white);
    border: solid 2px var(--graphite);
    padding: 1rem;
    font-size: 1.3rem;
    color: var(--graphite);
    margin-top: 1rem;
    margin-bottom: 2rem;
`;

export const ContainerButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    button {
        width: 9rem;
        margin: 0 15px;
    }
`;

export const BottomBar = styled.div`
    width: 85%;
    height: 2px;
    background-color: var(--black);
    margin: 2rem 0;

    @media (max-width: 1000px) {
        width: 100%;
    }
`;
export const ContainerInfos = styled.div`
    display: flex;
    width: 70%;
    margin: 4rem auto 1rem;
    justify-content: center;

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
    }

    div.texts {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        h1 {
            margin-bottom: 1rem;
        }

        ul li {
            font-size: 1.3rem;
        }
    }
`;
export const TicketIcon = styled(BsChatSquareQuoteFill)`
    height: 8rem;
    width: 8rem;

    @media (max-width: 650px) {
        margin-bottom: 2rem;
    }
`;
