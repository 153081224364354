import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div``;

export const Subtitle = styled.h3`
    color: var(--white);
    font-size: 1.2rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
`;

export const PixCopyAndPaste = styled.p`
    background-color: var(--white);
    padding: 0.5rem;
    border-radius: 6px;
    color: var(--black);
    font-size: 16px !important;
    word-wrap: break-word;
    max-width: 400px;
    margin: auto;
`;

export const PixQrCodeContainer = styled.div`
    margin: 1rem auto 2rem;
    text-align: center;
`;

export const PixInstructionsBeforePaymentContainer = styled.div`
    background-color: var(--white);
    padding: 2rem;
    border-radius: 6px;
    color: var(--black);
    font-size: 20px !important;
    word-wrap: break-word;
    margin: auto;
    text-align: justify;
`;

export const Text = styled.p`
    text-align: center;
    color: var(--white) !important;
    font-size: 16px !important;
`;

export const CopyButton = styled(Button)`
    padding: 0.5rem 1rem;
    display: block;
    margin: 1rem auto;
    font-size: 14px !important;
    span {
        color: var(--black) !important;
        font-size: 14px !important;
    }
`;
