import { Container, Content, ConatinerRules, CloseButton, ContainerTitle } from './styles';

interface IProps {
    showOverlay: () => void;
    regulation: string;
}

export function OverlayTermsOfCampaign({ showOverlay, regulation }: IProps): JSX.Element {
    return (
        <Container>
            <Content>
                <ConatinerRules>
                    <ContainerTitle>
                        <h1>Regulamento da campanha</h1>
                        <CloseButton fill="#000" size={30} onClick={() => showOverlay()} />
                    </ContainerTitle>
                    <span>{regulation}</span>
                </ConatinerRules>
            </Content>
        </Container>
    );
}
