import { Input } from 'components/Input';
import { useEffect, useState } from 'react';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { Button } from 'components/Button';
import { updateUserValidator } from 'validators/updateUser';
import getValidationErrors from 'utils/getValidationErrors';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Container, ContainerButtons, Content } from './styles';

interface IUserData {
    id: string;
    name: string;
    nickname: string;
    email: string;
    document: string;
    documentName: string;
}

export function MyProfile(): JSX.Element {
    const [userData, setUserData] = useState<IUserData>({} as IUserData);
    const { addError, addSuccess } = useToast();
    const [errors, setErrors] = useState({});
    const history = useHistory();
    useEffect(() => {
        async function handleGetUserData() {
            try {
                const response = await api.get('/users/authenticated');
                setUserData({
                    id: response.data.organizer.id,
                    email: response.data.email,
                    name: response.data.organizer.name,
                    nickname: response.data.organizer.nickname || '',
                    document: response.data.organizer.documentNumber,
                    documentName: response.data.organizer.documentName,
                });
            } catch (error) {
                addError('Erro ao obter dados do usuário');
            }
        }
        handleGetUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleUpdateUser() {
        try {
            setErrors({});

            const { document, name, email, nickname } = userData;

            await updateUserValidator.validate({ name, email, nickname, document }, { abortEarly: false });

            const data = {
                document,
                name,
                email,
                nickname,
                active: true,
            };

            try {
                api.patch(`organizers/${userData.id}`, data);
                addSuccess('Dados atualizados');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                addError('Não foi possível atualizar os dados');
                if (error.response.data.error.message) addError(error.response.data.error.message);
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
            }
            addError('Não foi possível atuaizar os dados');
        }
    }

    return (
        <Container>
            <Content>
                <h1>Meus dados</h1>

                <Input
                    name="name"
                    label="Nome"
                    value={userData.name}
                    errors={errors}
                    onChange={e => setUserData({ ...userData, name: e.target.value })}
                />
                <Input
                    name="nickname"
                    label="Apelido"
                    value={userData.nickname}
                    errors={errors}
                    onChange={e => setUserData({ ...userData, nickname: e.target.value })}
                />
                <Input
                    name="email"
                    label="Email"
                    value={userData.email}
                    errors={errors}
                    onChange={e => setUserData({ ...userData, email: e.target.value })}
                />
                <Input
                    name="document"
                    label="CPF"
                    value={userData.document}
                    errors={errors}
                    onChange={e => setUserData({ ...userData, document: e.target.value })}
                />

                <ContainerButtons>
                    <Button color="red" onClick={() => history.goBack()}>
                        Voltar
                    </Button>
                    <Button color="green" onClick={() => handleUpdateUser()}>
                        Salvar
                    </Button>
                </ContainerButtons>
            </Content>
        </Container>
    );
}
