import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useToast } from 'hooks/toast';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'services/api';
import { formatPrizeDrawDate } from 'utils/formatDate';
import {
    ButtonSetPage,
    Container,
    ContainerFilter,
    ContainerTable,
    ContainerTablePages,
    ContainerTitleFilter,
    Content,
    TableOrganizers,
    Title,
} from './styles';

const PAGINATION_LENGTH = 10;

interface IOrganizer {
    id: string;
    name: string;
    documentNumber: string;
    documentName: string;
    totalAmountRaised: number;
    amountOfActiveCampaigns: number;
    active: boolean;
    createdAt: string;
}

interface IFilter {
    name: string;
    createdAtBegin: string;
    createdAtEnd: string;
    document: string;
}

export function ViewOrganizers(): JSX.Element {
    const [organizers, setOrganzers] = useState<Array<IOrganizer>>([] as Array<IOrganizer>);
    const [filterData, setFilterData] = useState<IFilter>({
        name: '',
        createdAtBegin: '',
        createdAtEnd: '',
        document: '',
    });
    const history = useHistory();
    const { addError } = useToast();
    const [filter, setFilter] = useState(false);
    const [pageSelected, setPageSelected] = useState(1);
    const [pages, setPages] = useState<Array<number>>([]);

    function handleGetPages(total: number) {
        const newPages = [];
        for (let i = 0; i < total / PAGINATION_LENGTH; i += 1) {
            newPages.push(i + 1);
        }
        setPages(newPages);
    }

    async function getOrganizers(page: number) {
        try {
            const response = await api.get(`organizers?page=${page}`);
            handleGetPages(response.data.count);
            setOrganzers(response.data.data);
        } catch (error) {
            addError('Erro ao carregar organizadores');
        }
    }
    useEffect(() => {
        setFilterData({
            name: '',
            createdAtBegin: '',
            createdAtEnd: '',
            document: '',
        });
    }, []);

    useEffect(() => {
        getOrganizers(pageSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleFormatDocumentToSend(document: string) {
        const documentFormated = document.replace(/\.|-|_/g, '');
        return documentFormated;
    }

    function handleFormatDocument(document: string, type: string) {
        const arrayDocument = document.split('');
        if (type === 'CPF') {
            arrayDocument.splice(3, 0, '.');
            arrayDocument.splice(7, 0, '.');
            arrayDocument.splice(11, 0, '-');

            let formatedDocument = '';
            arrayDocument.forEach(obj => {
                formatedDocument += obj;
            });

            return formatedDocument;
        }

        arrayDocument.splice(3, 0, '.');
        arrayDocument.splice(7, 0, '.');
        arrayDocument.splice(11, 0, '/');
        arrayDocument.splice(16, 0, '-');

        let formatedDocument = '';
        arrayDocument.forEach(obj => {
            formatedDocument += obj;
        });

        return formatedDocument;
    }
    async function handleSearchOrganizerByFilter(page: number) {
        const url = 'organizers';
        const params: { [key: string]: string } = {};

        if (filterData.name?.length) {
            setFilter(true);
            params.name = filterData.name;
        } else if (filterData.document?.length) {
            setFilter(true);
            params.documentNumber = filterData.document;
        } else if (filterData.createdAtBegin?.length && filterData.createdAtEnd?.length) {
            setFilter(true);
            params.createdAtBegin = filterData.createdAtBegin;
            params.createdAtEnd = filterData.createdAtEnd;
        } else if (filterData.createdAtBegin?.length) {
            setFilter(true);
            params.createdAtBegin = filterData.createdAtBegin;
        } else if (filterData.createdAtEnd?.length) {
            setFilter(true);
            params.createdAtEnd = filterData.createdAtEnd;
        } else {
            setFilter(false);
        }
        params.page = String(page);
        try {
            const response = await api.get(url, { params });
            setOrganzers(response.data.data);
            handleGetPages(response.data.count);
        } catch (error) {
            addError('Erro ao obter organizadores');
        }
    }
    async function handleTablePagination(page: number) {
        if (filter === true) {
            setPageSelected(page);
            handleSearchOrganizerByFilter(page);
        } else {
            setPageSelected(page);
            getOrganizers(page);
        }
    }
    return (
        <Container>
            <Content>
                <ContainerTitleFilter>
                    <Title>Organizadores</Title>
                    <ContainerFilter>
                        <div className="big-fields">
                            <div className="container-name">
                                <span>Nome do organizador</span>
                                <Input
                                    name="organizerName"
                                    value={filterData.name}
                                    onChange={e => {
                                        setFilterData({ ...filterData, name: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="created-by">
                                <span>CPF do organizador</span>
                                <Input
                                    name="organizerCpf"
                                    type="masked"
                                    mask="999.999.999-99"
                                    value={filterData.document}
                                    onChange={e => {
                                        const value = handleFormatDocumentToSend(e.target.value);
                                        setFilterData({ ...filterData, document: value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="container-input">
                            <span>Data de cadastro inicial</span>
                            <Input
                                name="registerDate"
                                type="date"
                                value={filterData.createdAtBegin}
                                onChange={e => {
                                    setFilterData({
                                        ...filterData,
                                        createdAtBegin: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className="container-input">
                            <span>Data de cadastro final</span>
                            <Input
                                name="prizeDrawDate"
                                type="date"
                                value={filterData.createdAtEnd}
                                onChange={e => {
                                    setFilterData({
                                        ...filterData,
                                        createdAtEnd: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="container-button">
                            <Button color="green" onClick={() => handleSearchOrganizerByFilter(pageSelected)}>
                                Filtrar
                            </Button>
                        </div>
                    </ContainerFilter>
                </ContainerTitleFilter>
                <ContainerTable>
                    <TableOrganizers>
                        <thead>
                            <tr>
                                <td>Documento</td>
                                <td>Nome do organizador</td>
                                <td>Data de inscrição</td>
                                <td>Qtde. de campanhas ativas</td>
                                <td>Valor movimentado</td>
                                <td>Ações</td>
                            </tr>
                        </thead>
                        <tbody>
                            {organizers.map(obj => {
                                return (
                                    <tr key={obj.id}>
                                        <td>{handleFormatDocument(obj.documentNumber, obj.documentName)}</td>
                                        <td>{obj.name}</td>
                                        <td>{formatPrizeDrawDate(obj.createdAt)}</td>
                                        <td>{obj.amountOfActiveCampaigns}</td>
                                        <td>R$ {obj.totalAmountRaised}</td>
                                        <td className="actions">
                                            <Button
                                                color="purple"
                                                onClick={() =>
                                                    history.push('detalhes-do-organizador', {
                                                        organizerName: obj.name,
                                                        organizerId: obj.id,
                                                    })
                                                }
                                            >
                                                Ver detalhes
                                            </Button>
                                            <Button
                                                color="yellow"
                                                onClick={() =>
                                                    history.push('/editar-organizador', {
                                                        organizerId: obj.id,
                                                    })
                                                }
                                            >
                                                Editar
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </TableOrganizers>
                </ContainerTable>
                <ContainerTablePages>
                    {pages.map(page => {
                        return (
                            <ButtonSetPage
                                key={page}
                                selected={pageSelected === page}
                                onClick={() => handleTablePagination(page)}
                            >
                                {page}
                            </ButtonSetPage>
                        );
                    })}
                </ContainerTablePages>
            </Content>
        </Container>
    );
}
