import styled, { createGlobalStyle } from 'styled-components';
import { colors } from 'styles/colors';

export const Loading = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const GlobalStyles = createGlobalStyle`
    :root {
        --blue-dark: ${colors.blueDark};
        --blue-regular: ${colors.blueRegular};
        --blue-aqua: ${colors.blueAqua};
        --blue-light: ${colors.blueLight};
        --green: ${colors.green};
        --red: ${colors.red};
        --yellow: ${colors.yellow};
        --purple: ${colors.purple};
        --black: ${colors.black};
        --graphite: ${colors.graphite};
        --white: ${colors.white};
        --light-gray: ${colors.lightGray};
        --gray: ${colors.gray};
        --dark-gray: ${colors.darkGray};
        --success: ${colors.success};
        --danger: ${colors.danger};
        --primary: ${colors.primary};
        --secondary: ${colors.secondary};
        --darkGreen: ${colors.darkGreen};
        --blackLight: ${colors.blackLight};
    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        color: var(--black);
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }

        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

    body {
        -webkit-font-smoothing: antialised;
    }

    body, input, textarea, button {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
      font-weight: 700;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    * {
    scrollbar-width: thin;
    scrollbar-color:  var(--dark-gray) var(--white);
    }
      /* Works on Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
      width: 0.3125rem;
      height: 0.6125rem;
    }

    ::-webkit-scrollbar-track {
      background: var(--white);
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--dark-gray);
      border-radius: 1.25rem;
    }
`;
