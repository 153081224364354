const timezone = 'America/Sao_Paulo';

export function formatDate(date: string): string {
    const formatedDate = date.substring(0, 10);
    return formatedDate;
}

export function formatPrizeDrawDate(date: string): string {
    const formatedDate = date.substring(0, 10);
    const correctDate = formatedDate.split('-');
    return `${correctDate[2]}/${correctDate[1]}/${correctDate[0]}`;
}

export function formatDateAndHours(date: string): string {
    const hours = date.substring(11, 13);
    const minutes = date.substring(14, 16);
    const formatedDate = formatPrizeDrawDate(date);
    return `${formatedDate} às ${hours}:${minutes}h`;
}

/**
 * A função recebe uma data no formato UTC e converte para
 * o formato padrão da America/Sao_Paulo.
 * @param date data em UTC
 * @returns Exemplo de retorno: 17/03/2022 19:48:26
 */
export function formatDateAndHoursUTC(date: string): string {
    const data = new Date(date).toLocaleString('pt-BR', { timeZone: timezone });
    return data;
}
