import * as Yup from 'yup';

export const rescuePixValidator = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    pixKey: Yup.string().required('Campo obrigatório'),
});

export const rescueBankValidator = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    bank: Yup.string().required('Campo obrigatório'),
    agency: Yup.string().required('Campo obrigatório'),
    account: Yup.string().required('Campo obrigatório'),
});
