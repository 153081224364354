import styled from 'styled-components';

export const Header = styled.header`
    background-color: var(--black);
    width: 100vw;
    padding: 1.5rem;
    display: flex;
    justify-content: center;

    img {
        height: 4rem;
    }
`;

export const Footer = styled.footer`
    background-color: var(--primary);
    width: 100vw;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 2rem;
    }

    span {
        font-weight: bold;
        margin-top: 0.5rem;
    }
`;

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(156, 246, 171, 1) 62%, rgba(53, 236, 84, 1) 100%);
`;

export const HeaderLogin = styled.div`
    display: flex;
    align-items: center;

    div.text {
        display: flex;
        flex-direction: column;

        ul li {
            font-size: 1.3rem;
        }
    }
`;

export const BottomBar = styled.div`
    width: 85%;
    height: 2px;
    background-color: var(--black);
    margin: 2rem 0;
`;

export const Content = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
`;

export const Image = styled.img`
    margin-right: 2rem;

    @media (max-width: 1000px) {
        width: 7rem;
    }

    @media (max-width: 480px) {
        display: none;
    }
`;

export const LoginContent = styled.div`
    height: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;

    @media (max-width: 350px) {
        padding: 0 1rem;
    }

    label {
        margin-left: 10px;
        margin-bottom: 3px;
        display: block;
        font-size: 1.2rem;
    }

    h2 {
        font-weight: 500;
        margin-bottom: 1.5rem;
        text-align: center;
    }

    div.buttons-container {
        margin-top: 25px;
        display: flex;

        button:first-of-type {
            margin-right: 10px;
        }
    }

    p {
        margin: 1.5rem 0 0.8rem;
        font-size: 0.9rem;
        font-weight: thin;
        text-align: center;
    }
`;

export const ContainerInput = styled.div`
    width: 30rem;

    @media (max-width: 480px) {
        width: 20rem;
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
`;

export const ContentButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;

    button {
        background-color: transparent;
        border: none;
        font-size: 1.4rem;
        font-weight: bold;
    }
`;

export const ContainerButtonLogin = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
`;

export const ButtonLogin = styled.button`
    background-color: var(--primary);
    border: none;
    padding: 1rem;
    border-radius: 5px;
    width: 12rem;
    align-self: flex-end;

    span {
        color: var(--light-gray);
        font-size: 1.5rem;
    }
`;
