import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #efefef;

    @media (max-width: 600px) {
        padding: 0;
    }
`;

export const Content = styled.div`
    width: 100%;
    min-height: calc(100vh - 12rem);
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(156, 246, 171, 1) 62%, rgba(53, 236, 84, 1) 100%);
`;
