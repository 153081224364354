export function ValidateImageFormat(image: string): boolean {
    const format = image.split('.');
    if (
        format[format.length - 1] === 'png' ||
        format[format.length - 1] === 'jpeg' ||
        format[format.length - 1] === 'jpg'
    )
        return true;
    return false;
}
