import { useCallback, useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { rescuePixValidator, rescueBankValidator } from 'validators/Rescue';
import { useToast } from 'hooks/toast';
import getValidationErrors from 'utils/getValidationErrors';
import * as Yup from 'yup';
import api from 'services/api';
import { useLocation, useHistory } from 'react-router-dom';

import {
    ButtonPaymentMethod,
    Container,
    ContainerBankInformation,
    ContainerButton,
    ContainerFinallyRescue,
    ContainerInput,
    ContainerPaymentButtons,
    ContainerRescueData,
    Content,
    PaymentData,
    Select,
    Subtitle,
    Title,
} from './styles';

interface IProps {
    idCampaign: string;
}

export function Rescue(): JSX.Element {
    const [errors, setErrors] = useState({});
    const [name, setName] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [totalAmountRaised, setTotalAmountRaised] = useState(0);
    const [bank, setBank] = useState('');
    const [agency, setAgency] = useState('');
    const [account, setAccount] = useState('');
    const [pixKey, setPixKey] = useState('');
    const [pixKeyType, setPixKeyType] = useState('');
    const [note, setNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState('pix');
    const { addError, addSuccess } = useToast();
    const history = useHistory();
    const location = useLocation<IProps>();

    useEffect(() => {
        async function getCampaingData() {
            try {
                const response = await api.get(`purchases/campaign/${location.state.idCampaign}`);
                setCampaignName(response.data.campaign.name);
                setTotalAmountRaised(response.data.campaingnSummary.totalAmountRaised);
            } catch (error) {
                addError('Erro ao obter dados da campanha');
            }
        }
        getCampaingData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRescueWithPix = useCallback(async () => {
        setIsLoading(true);
        try {
            setErrors({});

            await rescuePixValidator.validate({ name, pixKey }, { abortEarly: false });

            const data = {
                campaignId: location.state.idCampaign,
                recipientName: name,
                pixKey,
                pixKeyType,
                note,
                bankTransferType: 'PIX',
            };

            try {
                await api.post('redeems', data);
                addSuccess('Solicitação de resgate enviada');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                addError('Não foi possível solicitar o resgate');
                if (error.response.data.error.message) addError(error.response.data.error.message);
            } finally {
                setIsLoading(false);
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
            }
            addError('Não foi possível realizar a solicitação de resgate');
        } finally {
            setIsLoading(false);
        }
    }, [name, pixKey, addError, addSuccess, location.state.idCampaign, note, pixKeyType]);

    const handleRescueWithBank = useCallback(async () => {
        setIsLoading(true);
        try {
            setErrors({});

            await rescueBankValidator.validate({ name, bank, agency, account }, { abortEarly: false });

            const data = {
                campaignId: location.state.idCampaign,
                recipientName: name,
                bankName: bank,
                bankAgency: agency,
                bankAccountNumber: account,
                note,
                bankTransferType: 'TED',
            };

            try {
                await api.post('redeems', data);
                addSuccess('Solicitação de resgate enviada');
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                addError('Não foi possível solicitar o resgate');
                if (error.response.data.error.message) addError(error.response.data.error.message);
            } finally {
                setIsLoading(false);
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
            }
            addError('Não foi possível realizar a solicitação de resgate');
        } finally {
            setIsLoading(false);
        }
    }, [name, bank, account, agency, addError, addSuccess, note, location.state.idCampaign]);

    return (
        <Container>
            <Content>
                <Title>Resgate</Title>
                <Subtitle>Campanha {campaignName}</Subtitle>
                <ContainerRescueData>
                    <PaymentData>
                        <ContainerPaymentButtons>
                            <ButtonPaymentMethod
                                selected={paymentMethodSelected === 'pix'}
                                onClick={() => setPaymentMethodSelected('pix')}
                            >
                                PIX
                            </ButtonPaymentMethod>
                            <ButtonPaymentMethod
                                selected={paymentMethodSelected === 'ted'}
                                onClick={() => setPaymentMethodSelected('ted')}
                            >
                                Transferência bancária
                            </ButtonPaymentMethod>
                        </ContainerPaymentButtons>
                        <ContainerBankInformation>
                            <ContainerInput>
                                <Input
                                    name="name"
                                    placeholder="Nome do favorecido"
                                    label="Nome do favorecido"
                                    errors={errors}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </ContainerInput>
                            <p className="label">Preencha a forma de resgate:</p>
                            {paymentMethodSelected === 'pix' ? (
                                <>
                                    <ContainerInput>
                                        <Select value={pixKeyType} onChange={e => setPixKeyType(e.target.value)}>
                                            <option value="CPF">CPF</option>
                                            <option value="CNPJ">CNPJ</option>
                                            <option value="Celular">Celular</option>
                                            <option value="Email">Email</option>
                                            <option value="Chave aleatória">Chave aleatória</option>
                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput>
                                        {pixKeyType === 'CPF' && (
                                            <Input
                                                name="pixKey"
                                                placeholder="Chave Pix"
                                                label="Chave Pix"
                                                type="masked"
                                                mask="999.999.999-99"
                                                errors={errors}
                                                value={pixKey}
                                                onChange={e => setPixKey(e.target.value)}
                                            />
                                        )}
                                        {pixKeyType === 'CNPJ' && (
                                            <Input
                                                name="pixKey"
                                                placeholder="Chave Pix"
                                                label="Chave Pix"
                                                type="masked"
                                                mask="99.999.999/9999-99"
                                                errors={errors}
                                                value={pixKey}
                                                onChange={e => setPixKey(e.target.value)}
                                            />
                                        )}
                                        {pixKeyType === 'Celular' && (
                                            <Input
                                                name="pixKey"
                                                placeholder="Chave Pix"
                                                label="Chave Pix"
                                                type="masked"
                                                mask="(99) 99999-9999"
                                                errors={errors}
                                                value={pixKey}
                                                onChange={e => setPixKey(e.target.value)}
                                            />
                                        )}
                                        {pixKeyType === 'Email' && (
                                            <Input
                                                name="pixKey"
                                                placeholder="Chave Pix"
                                                label="Chave Pix"
                                                errors={errors}
                                                value={pixKey}
                                                onChange={e => setPixKey(e.target.value)}
                                            />
                                        )}
                                        {pixKeyType === 'Chave aleatória' && (
                                            <Input
                                                name="pixKey"
                                                placeholder="Chave Pix"
                                                label="Chave Pix"
                                                errors={errors}
                                                value={pixKey}
                                                onChange={e => setPixKey(e.target.value)}
                                            />
                                        )}
                                    </ContainerInput>
                                </>
                            ) : (
                                <>
                                    <ContainerInput>
                                        <Input
                                            name="bank"
                                            placeholder="Nome do banco"
                                            label="Nome do banco"
                                            errors={errors}
                                            value={bank}
                                            onChange={e => setBank(e.target.value)}
                                        />
                                    </ContainerInput>
                                    <ContainerInput className="agency">
                                        <Input
                                            name="agency"
                                            placeholder="Agência"
                                            label="Agência"
                                            errors={errors}
                                            value={agency}
                                            onChange={e => setAgency(e.target.value)}
                                        />
                                    </ContainerInput>
                                    <ContainerInput className="account">
                                        <Input
                                            name="account"
                                            placeholder="Nº da conta com o dígito"
                                            label="Nº da conta com o dígito"
                                            value={account}
                                            errors={errors}
                                            onChange={e => setAccount(e.target.value)}
                                        />
                                    </ContainerInput>
                                </>
                            )}
                        </ContainerBankInformation>
                    </PaymentData>
                    <ContainerFinallyRescue>
                        <span>Total a ser resgatado: R$ {totalAmountRaised}</span>
                        <textarea
                            name="obs"
                            placeholder="Observações"
                            value={note}
                            onChange={e => setNote(e.target.value)}
                        />
                        <ContainerButton>
                            <Button color="red" onClick={() => history.goBack()}>
                                Voltar
                            </Button>
                            <Button
                                color="green"
                                onClick={() => {
                                    if (paymentMethodSelected === 'pix') handleRescueWithPix();
                                    else handleRescueWithBank();
                                }}
                            >
                                {isLoading ? 'Carregando...' : 'Resgatar'}
                            </Button>
                        </ContainerButton>
                    </ContainerFinallyRescue>
                </ContainerRescueData>
            </Content>
        </Container>
    );
}
