import { useCallback, useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import * as Yup from 'yup';
import { useToast } from 'hooks/toast';
import { resetPasswordValidator } from 'validators/resetPassword';
import getValidationErrors from 'utils/getValidationErrors';
import api from 'services/api';
import { useLocation, useHistory } from 'react-router-dom';

import { Container, ContentInput } from './styles';

export function ResetPassword(): JSX.Element {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { addSuccess, addError } = useToast();
    const [isTokenValid, setIsTokenValid] = useState(true);
    const [token, setToken] = useState('');
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        const arrayPathname = pathname.split('/');
        const tokenReset = arrayPathname[arrayPathname.length - 1];
        setToken(tokenReset);

        async function handleValidateToken() {
            try {
                await api.post(`auth/validate-token-reset-password/${tokenReset}`);
                setIsTokenValid(true);
            } catch (error) {
                addError('O token é inválido, por favor verifique o link no seu email e tente novamente');
                setIsTokenValid(false);
            }
        }
        handleValidateToken();
    }, [addError, pathname]);

    const handleResetPassword = useCallback(async () => {
        try {
            setIsLoading(true);
            setErrors({});
            await resetPasswordValidator.validate({ password, confirmPassword }, { abortEarly: false });

            try {
                await api.post('auth/reset-password', {
                    token,
                    password,
                });
                addSuccess('Senha alterada com sucesso');
                history.push('/login');
            } catch (error) {
                addError('Erro ao alterar senha');
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
                return;
            }

            addError('Erro ao recuperar senha');
        } finally {
            setIsLoading(false);
        }
    }, [password, confirmPassword, addError, addSuccess, token, history]);

    return (
        <Container>
            {isTokenValid ? (
                <>
                    <h1>Resetar senha</h1>
                    <ContentInput>
                        <Input
                            name="password"
                            label="Senha"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            errors={errors}
                        />
                        <Input
                            name="confirmPassword"
                            label="Confirme sua senha"
                            type="password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            errors={errors}
                        />
                        <div className="container-button">
                            <Button color="primary" onClick={() => handleResetPassword()}>
                                {isLoading ? 'Carregando...' : 'Recuperar senha'}
                            </Button>
                        </div>
                    </ContentInput>
                </>
            ) : (
                <h1>Token inválido. Por favor verifique o link no seu email e tente novamente</h1>
            )}
        </Container>
    );
}
