import styled from 'styled-components';
import { DefaultPage } from '../../_DefaultPage';

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 24rem);
`;

export const ContainerCampaign = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;

    @media (max-width: 1400px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`;

export const CampaignCard = styled.div`
    display: flex;
    padding: 1rem;
    width: 98%;
    max-width: 44rem;
    min-height: 20rem;
    border: solid 2px var(--graphite);
    border-radius: 10px;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    background-color: var(--secondary);

    @media (max-width: 1400px) {
        margin-bottom: 2rem;
    }

    @media (max-width: 620px) {
        width: 30rem;
        height: auto;
        flex-direction: column;
    }
    @media (max-width: 600px) {
        margin-top: 2rem;
    }

    @media (max-width: 430px) {
        width: 98%;
        height: auto;
    }

    div.image {
        width: 15rem;
        height: 15rem;
        border-radius: 10px;

        @media (max-width: 600px) {
            width: 10rem;
            height: 10rem;
        }
    }

    div.campaign-informations {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        flex: 1;

        h1 {
            color: var(--graphite);
            font-size: 2rem;
            margin-bottom: 1rem;

            @media (max-width: 600px) {
                font-size: 1.5rem;
            }
        }

        span {
            color: var(--graphite);
            font-size: 1.3rem;
            line-height: 2rem;

            @media (max-width: 600px) {
                font-size: 1.2rem;
                line-height: 1.5rem;
            }
        }

        div.prizes {
            display: flex;
            flex-direction: column;
            overflow: auto;
            margin-top: 1rem;
            color: var(--black);

            @media (max-width: 600px) {
                strong {
                    font-size: 1.3rem;
                }
            }

            p {
                margin: 0 5px;
                font-size: 1.1rem;
                color: var(--graphite);
            }
        }
    }
`;

export const CampaingnResume = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 98%;
    max-width: 44rem;
    min-height: 20rem;
    border: solid 2px var(--graphite);
    border-radius: 10px;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    background-color: var(--secondary);
    overflow-y: auto;

    @media (max-width: 600px) {
        width: 30rem;
        height: 15rem;
    }
    @media (max-width: 430px) {
        width: 98%;
    }

    h1 {
        color: var(--graphite);
        font-size: 2rem;
        margin-bottom: 1rem;
        width: 100%;
        text-align: center;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    span {
        color: var(--graphite);
        font-size: 1.3rem;
        line-height: 2rem;

        @media (max-width: 600px) {
            font-size: 1.2rem;
            line-height: 1.5rem;
        }
    }

    span.sold-number {
        margin-top: 2rem;
    }
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 1.7rem;
    background-color: var(--gray);
    border-radius: 5px;
    margin-bottom: 1rem;
`;

export const ProgressBarValue = styled.div`
    height: 100%;
    background-color: #fa9c17;
    border-radius: 5px;
    width: ${props => props.theme.value};
    max-width: 100%;
    transition: 1s ease;
    transition-delay: 0.5s;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.5rem;

    :hover {
        span {
            opacity: 1;
            transition: all 200ms;
        }
    }

    span {
        color: black;
        font-size: 1rem;
        opacity: 0;
        cursor: default;
    }
`;

export const ContainerButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;

    button {
        margin-right: 1rem;
    }
    @media (max-width: 700px) {
        flex-direction: column;

        button {
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }
`;

export const ContainerTable = styled.div`
    width: 100%;
    overflow: auto;
    min-height: 10rem;
`;

export const TableSales = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
    min-width: 800px;

    td {
        padding: 1rem;
        border: solid 1px var(--graphite);
    }

    thead tr td {
        font-size: 1.05rem;
        font-weight: bold;
        background-color: var(--gray);
    }
    tbody tr td {
        font-size: 0.9rem;
    }
    .centered-td {
        text-align: center;
    }
`;

export const ContainerTablePages = styled.div`
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: end;
    align-items: center;
    button {
        padding: 0.5rem 1rem;
        background-color: transparent;
        border: none;
        font-size: 1.2rem;
        color: var(--graphite);
    }
    select {
        margin-left: 10px;
        width: 60px;
        height: 30px;
        max-height: 100px;
        border: 1px solid #ddd;
        text-align-last: center;
    }
`;

export const ImageCard = styled.div`
    height: 100%;
    width: 40%;
    border-radius: 5px;
    overflow: hidden;

    @media (max-width: 700px) {
        width: 50%;
    }

    @media (max-width: 620px) {
        height: auto;
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const PrizeDrawImage = styled.img`
    height: 100%;
`;

export const PrizeDrawName = styled.h3`
    color: var(--white);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
`;

export const ProfitMarginContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
`;

export const ProfitMarginItem = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    background: var(--white);
    padding: 1rem;
    border-radius: 8px;

    h3 {
        font-size: 20px;
    }

    span {
        font-size: 18px;
    }
`;

export const PurchaseStatus = styled.span<{
    $status: 'Cancelada' | 'Concluída' | 'Pendente' | 'Cancelada manualmente';
}>`
    text-align: center;
    display: block;
    width: 100%;
    color: var(--white);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    background-color: ${p => {
        switch (p.$status) {
            case 'Concluída':
                return 'var(--green)';
            case 'Pendente':
                return 'var(--yellow)';
            case 'Cancelada':
            case 'Cancelada manualmente':
                return 'var(--red)';
            default:
                return 'var(--black)';
        }
    }};
`;

export const FilterRadio = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
`;

export const FilterContainer = styled.div`
    background: var(--secondary);
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    gap: 0.5rem;
    margin: 0 0.8rem;
    font-weight: bold;
    border: 1px solid #000;
    align-items: center;

    @media (max-width: 700px) {
        margin: 0;
        width: 100%;
    }
`;

export const ContainerFilter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 3rem 0 0;

  @media (max-width: 850px) {
    width: 100%;
    flex-wrap: wrap;
  }

  div.big-fields {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 700px) {
      flex-direction: column;

      div.container-name {
        padding: 0;
      }
    }
  }

  div.container-name {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 0 0;

    @media (max-width: 700px) {
      width: 100%;
    }

    span {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  }
`;
