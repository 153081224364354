import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

export const consultTicketsValidator = Yup.object().shape({
    document: Yup.string()
        .test('validate-cpf-cpnj', 'CPF inválido', value => {
            if (value && cpf.isValid(value)) return true;
            return false;
        })
        .required('Campo obrigatório'),
});

export const consultTicketsEmailValidator = Yup.object().shape({
    email: Yup.string().required('Campo obrigatório').email('Endereço de email inválido'),
});
