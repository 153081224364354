import styled from 'styled-components';
import { IoMdCloseCircle } from 'react-icons/io';

export const Container = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 10;
`;

export const InputsGrid = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 970px) {
        flex-direction: column;
    }

    .select-type-fees {
        background: #fff !important;
        position: relative;
        border-style: solid;
        border-width: 2px;
        width: 100%;
        height: 2.64rem;
        border: 2px solid #4d4d4d;
        border-radius: 8px;
        margin-bottom: 0.5rem;
        opacity: revert;
    }
`;

export const Content = styled.div`
    width: 60rem;
    display: flex;
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    background-color: var(--white);
    padding: 2rem;
    border-radius: 5px;

    h1 {
        margin-bottom: 2rem;
    }
    h2 {
        margin-bottom: 1.5rem;
    }
    h3 {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    div.container-input {
        width: 47%;
        display: flex;
        flex-direction: column;

        @media (max-width: 970px) {
            width: 100%;
        }

        span.field-name {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
        }
    }
`;

export const ContainerButtons = styled.div`
    width: 100%;
    margin: 1rem;
    display: flex;
    justify-content: center;

    button {
        margin: 0 1rem;
    }
`;

export const CloseButton = styled(IoMdCloseCircle)`
    fill: var(--danger);
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: calc(50vh - 18rem);
    right: calc(50vw - 18rem);
    z-index: 4;
    opacity: 1;

    @media (max-width: 530px) {
        right: calc(50vw - 12rem);
    }
    @media (max-width: 350px) {
        right: calc(50vw - 10rem);
    }
`;

export const ContainerOthersFees = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const IrifeiFees = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 970px) {
        flex-direction: column;
    }
`;

export const ContainerInformartion = styled.div`
    width: 100%;
    margin: 1rem;

    span {
        font-size: 1.2rem;
    }
`;
