import { createContext, useCallback, useContext, useState, useEffect } from 'react';
import api from 'services/api';

import { User } from 'interfaces/user';
import axios from 'axios';
import { TOKEN, USER } from 'constants/environment';
import { useHistory } from 'react-router-dom';

import { IAPIResponseBodyUserAuthenticated } from 'interfaces/requestsApi';

import { useToast } from './toast';

interface SignInCredentials {
    login: string;
    password: string;
}

interface AuthContextData {
    user: User;
    loading: boolean;
    signIn: (credentials: SignInCredentials) => void;
    signOut: () => void;
}

interface IAuthProviderProps {
    children: JSX.Element | JSX.Element[];
}

interface IData {
    token: string;
    user: User;
}

const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children }: IAuthProviderProps): JSX.Element => {
    const [data, setData] = useState({} as IData);
    const [loading, setLoading] = useState(false);
    const { handleApiError, addError } = useToast();
    const history = useHistory();

    useEffect(() => {
        function loadStoragedData() {
            const token = localStorage.getItem(TOKEN);
            const user = localStorage.getItem(USER);

            if (token && user) {
                api.defaults.headers.authorization = `Bearer ${token}`;
                setData({ token, user: JSON.parse(user) });
            }
        }

        loadStoragedData();
    }, []);

    const signIn = useCallback(
        async ({ login, password }) => {
            try {
                setLoading(true);
                const response = await api.post('auth/authenticate', {
                    email: login,
                    password,
                });
                if (!response.data.token) {
                    addError('Ocorreu um erro ao autenticar o login, tente novamente');
                    return;
                }

                localStorage.setItem(TOKEN, response.data.token);

                api.defaults.headers.authorization = `Bearer ${response.data.token}`;

                const getUser = await api.get('users/authenticated');
                const userResponse: IAPIResponseBodyUserAuthenticated = getUser.data;

                if (userResponse) {
                    setData({
                        token: response.data.token,
                        user: {
                            _id: userResponse._id,
                            name: userResponse.name,
                            role: userResponse.role,
                            active: userResponse.active,
                        },
                    });
                    localStorage.setItem(
                        USER,
                        JSON.stringify({
                            _id: userResponse._id,
                            name: userResponse.name,
                            role: userResponse.role,
                            active: userResponse.active,
                        })
                    );
                }
                history.push('/home');
            } catch (err) {
                if (axios.isAxiosError(err)) {
                    if (err.response?.status === 403) {
                        addError('Usuário e/ou senha inválidos');
                    } else if (err.response?.status === 400) {
                        addError('Não foi possível logar, tente novamente');
                    } else {
                        handleApiError(err);
                    }
                }
            } finally {
                setLoading(false);
            }
        },
        [handleApiError, addError, history]
    );

    const signOut = useCallback(() => {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(USER);
        setData({} as IData);
        history.push('login');
    }, [history]);

    return (
        <AuthContext.Provider value={{ user: data.user, loading, signIn, signOut }}>{children}</AuthContext.Provider>
    );
};

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
}

export { AuthProvider, useAuth };
