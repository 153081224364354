import styled from 'styled-components';
import { DefaultPage } from '../../_DefaultPage';

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 24rem);
`;

export const ContainerStepsProgress = styled.div`
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
    span {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
`;

export const ProgressBar = styled.div`
    width: 80%;
    height: 1.7rem;
    background-color: var(--gray);
    border-radius: 2rem;
`;

export const ProgressBarValue = styled.div`
    height: 100%;
    background-color: #32cd32;
    border-radius: 5px;
    width: ${props => props.theme.width};
    transition: 1s ease;
    transition-delay: 0.5s;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.5rem;

    span {
        color: black;
        font-size: 1rem;
        opacity: 0;
        cursor: default;
    }
`;
