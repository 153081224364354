import { useEffect, useState } from 'react';

import { useToast } from 'hooks/toast';
import { IOrganizerCampaignsData } from 'interfaces/requestsApi';
import { Carousel } from 'react-bootstrap';
import defaultImage from 'assets/images/img/defaultPrizeDrawImage.png';
import api from 'services/api';
import { formatPrizeDrawDate } from 'utils/formatDate';
import {
    Container,
    ContainerInformations,
    ContainerTicket,
    ContainerTitleTicket,
    ContainerWinners,
    ContentTicket,
    ImageTicket,
    PrizeDrawImage,
    PrizeDrawName,
    Prizes,
    Ticket,
    TicketTitle,
    Title,
} from './styles';

interface IProps {
    token: string;
}

export function Winners({ token }: IProps): JSX.Element {
    const [campaign, setCampaign] = useState<IOrganizerCampaignsData>();
    const { addError } = useToast();

    useEffect(() => {
        async function handleGetCampaign() {
            try {
                const response = await api.get(`campaigns/affiliate/page/${token}`);
                if (response) setCampaign(response.data.data);
            } catch (error) {
                addError('Não foi possível obter os dados da campanha');
            }
        }
        handleGetCampaign();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Title>Essa campanha já foi finalizada.</Title>
            <ContainerTicket>
                <div className="content">
                    <Ticket>
                        <ContentTicket>
                            <ImageTicket>
                                <Carousel>
                                    {campaign?.prizePhotos.map(obj => {
                                        return (
                                            <Carousel.Item key={obj.id}>
                                                <PrizeDrawImage
                                                    className="d-block w-100"
                                                    src={obj.url}
                                                    alt={obj.name}
                                                />
                                                <Carousel.Caption>
                                                    <PrizeDrawName className="prize-draw-name">
                                                        {obj.name}
                                                    </PrizeDrawName>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        );
                                    })}
                                    {!campaign?.prizePhotos.length && (
                                        <Carousel.Item>
                                            <PrizeDrawImage
                                                className="d-block w-100"
                                                src={defaultImage}
                                                alt="Não há fotos dos prêmios dessa campanha"
                                            />
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            </ImageTicket>
                            <ContainerInformations>
                                <div className="texts">
                                    <ContainerTitleTicket>
                                        <TicketTitle>
                                            {campaign?.organizerName} - {campaign?.campaignName}
                                        </TicketTitle>
                                    </ContainerTitleTicket>
                                    <div className="infos">
                                        <span>Valor unitário: R$ {campaign?.ticketPrice}</span>
                                        <span>Tempo restante: Finalizado</span>
                                        {campaign?.prizeDrawDate && (
                                            <span>
                                                Data do sorteio: {'  '}
                                                {formatPrizeDrawDate(campaign?.prizeDrawDate)}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </ContainerInformations>
                        </ContentTicket>
                    </Ticket>
                    <Prizes>
                        <h1>Prêmio(s)</h1>

                        {campaign?.prize1 && <span>1º Prêmio: {campaign.prize1}</span>}
                        {campaign?.prize2 && <span>2º Prêmio: {campaign.prize2}</span>}
                        {campaign?.prize3 && <span>3º Prêmio: {campaign.prize3}</span>}
                        {campaign?.prize4 && <span>4º Prêmio: {campaign.prize4}</span>}
                        {campaign?.prize5 && <span>5º Prêmio: {campaign.prize5}</span>}
                    </Prizes>
                </div>
            </ContainerTicket>

            <ContainerWinners>
                <h1>Número(s) sorteado(s)</h1>
                <div className="container-awards">
                    {!campaign?.prize1 &&
                        !campaign?.prize2 &&
                        !campaign?.prize3 &&
                        !campaign?.prize4 &&
                        !campaign?.prize5 && <h2>Não houveram ganhadores</h2>}
                    {campaign?.ticketNumberWinner1 && (
                        <div className="award">
                            <h2>Prêmio 1</h2>
                            <span>{campaign?.ticketNumberWinner1}</span>
                        </div>
                    )}
                    {campaign?.ticketNumberWinner2 && (
                        <div className="award">
                            <h2>Prêmio 2</h2>
                            <span>{campaign?.ticketNumberWinner2}</span>
                        </div>
                    )}
                    {campaign?.ticketNumberWinner3 && (
                        <div className="award">
                            <h2>Prêmio 3</h2>
                            <span>{campaign?.ticketNumberWinner3}</span>
                        </div>
                    )}
                    {campaign?.ticketNumberWinner4 && (
                        <div className="award">
                            <h2>Prêmio 4</h2>
                            <span>{campaign?.ticketNumberWinner4}</span>
                        </div>
                    )}
                    {campaign?.ticketNumberWinner5 && (
                        <div className="award">
                            <h2>Prêmio 5</h2>
                            <span>{campaign?.ticketNumberWinner5}</span>
                        </div>
                    )}
                </div>
            </ContainerWinners>
        </Container>
    );
}
