import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Input } from 'components/Input';
import { useAuth } from 'hooks/auth';
import getValidationErrors from 'utils/getValidationErrors';
import { signInValidator } from 'validators/signIn';
import { useToast } from 'hooks/toast';

import logo from 'assets/images/img/logo_verde.png';
import logoFooter from 'assets/images/img/logo_fundo_verde.png';

import userIcon from 'assets/images/icons/user_circle.svg';

import { Button } from 'components/Button';
import {
    Container,
    ContainerButtonLogin,
    ContentButtons,
    Content,
    Image,
    LoginContent,
    ContainerButtons,
    HeaderLogin,
    BottomBar,
    ContainerInput,
    Header,
    Footer,
} from './styles';

export function SignIn(): JSX.Element {
    const { addError } = useToast();
    const history = useHistory();

    const { signIn, loading } = useAuth();
    const [errors, setErrors] = useState({});

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const handleSignIn = useCallback(async () => {
        try {
            setErrors({});

            await signInValidator.validate({ login, password }, { abortEarly: false });

            signIn({ login, password });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
                return;
            }

            addError('Não foi possível realizar o login');
        }
    }, [login, password, signIn, addError]);

    function handlePressEnter(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            handleSignIn();
        }
    }

    return (
        <Container>
            <Content>
                <Header>
                    <img src={logo} alt="iRifei" />
                </Header>
                <LoginContent>
                    <HeaderLogin>
                        <Image src={userIcon} alt="" />
                        <div className="text">
                            <h1>Espaço do vendedor</h1>
                            <ul>
                                <li>Utilize seus dados para fazer login na plataforma e gerenciar suas vendas</li>
                                <li>Ou crie uma conta, caso seja seu primeiro acesso</li>
                            </ul>
                        </div>
                    </HeaderLogin>
                    <BottomBar />

                    <ContainerInput>
                        <Input
                            name="login"
                            label="Email"
                            errors={errors}
                            value={login}
                            onChange={e => setLogin(e.target.value)}
                        />
                    </ContainerInput>

                    <ContainerInput>
                        <Input
                            name="password"
                            label="Senha"
                            type="password"
                            errors={errors}
                            value={password}
                            onKeyDown={e => handlePressEnter(e)}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </ContainerInput>

                    <ContainerButtons>
                        <ContentButtons>
                            <button type="button" onClick={() => history.push('esqueci-senha')}>
                                Esqueci minha senha
                            </button>
                        </ContentButtons>

                        <ContainerButtonLogin>
                            <Button onClick={handleSignIn} loading={loading} icon="send">
                                Entrar
                            </Button>
                        </ContainerButtonLogin>
                    </ContainerButtons>
                </LoginContent>
                <Footer>
                    <img src={logoFooter} alt="iRifei" />
                    <span>Hoje pode ser seu dia de sorte</span>
                </Footer>
            </Content>
        </Container>
    );
}
