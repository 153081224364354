import { Button } from 'components/Button';
import { useState } from 'react';
import { Container, Content, CloseButton, ContainerButtons, ContainerTitle } from './styles';

interface IProps {
    showOverlay: () => void;
    requestPublishCampaign: (id: string) => Promise<void>;
    campaignId: string;
}
export function OverlayRequestPublishCampaign({
    showOverlay,
    campaignId,
    requestPublishCampaign,
}: IProps): JSX.Element {
    const [isClicked, setIsClicked] = useState(false);
    return (
        <Container>
            <Content>
                <ContainerTitle>
                    <div className="text">
                        <h1>Você está prestes a solicitar a publicação de sua campanha.</h1>
                        <h1> Por favor, esteja ciente dos seguintes detalhes: </h1>
                        <br />
                        <ul>
                            <li>Sua solicitação foi encaminhada e será publicada em até 2 dias úteis.</li>
                            <li>
                                Após essa ação, a quantidade máxima de bilhetes e a quantidade de bilhetes dos
                                associados já cadastrados não poderão ser alteradas.
                            </li>
                            <li>
                                Ao publicar uma campanha, todos os associados já cadastrados receberão um e-mail com o
                                link personalizado da campanha.
                            </li>
                            <li>
                                Se durante a revisão forem identificadas divergências ou necessidades de ajustes, o
                                setor responsável entrará em contato diretamente com o responsável pela solicitação.{' '}
                            </li>
                            <br />
                        </ul>
                        <h1>Você confirma a solicitação de publicação da campanha? </h1>
                    </div>
                    <CloseButton fill="#f00" size={30} onClick={() => showOverlay()} />
                </ContainerTitle>

                <ContainerButtons>
                    <Button color="red" onClick={() => showOverlay()}>
                        Cancelar
                    </Button>
                    <Button
                        color="green"
                        onClick={() => {
                            requestPublishCampaign(campaignId);
                            setIsClicked(true);
                        }}
                        disabled={isClicked}
                    >
                        Confirmar
                    </Button>
                </ContainerButtons>
            </Content>
        </Container>
    );
}
