import styled from 'styled-components';

import { DefaultPage } from '../../_NewDefaultPage';

interface PropsPolicyTerms {
    isError: boolean;
}

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 24rem);
`;

export const ContainerTicket = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: auto;
    padding: 0 2rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(156, 246, 171, 1) 62%, rgba(53, 236, 84, 1) 100%);

    @media (max-width: 1150px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Prizes = styled.div`
    width: 50%;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    overflow-y: auto;
    min-width: 20rem;

    @media (max-width: 1300px) {
        margin-right: 1rem;
    }

    @media (max-width: 1150px) {
        width: 100%;
    }

    @media (max-width: 1000px) {
        width: 50%;
    }

    @media (max-width: 600px) {
        width: 100%;
        padding-left: 0;
    }

    h1 {
        font-size: 2rem;
        font-weight: 800;
        text-decoration: underline;
        color: var(--black);
        margin-left: 0.5rem;
        margin-bottom: 1rem;
    }

    span {
        color: var(--black);
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
`;

export const Ticket = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.7rem;
    padding-bottom: 0;
    height: 27rem;
    margin-bottom: 2rem;

    @media (max-width: 1050px) {
        flex-direction: column;
        height: auto;
    }
`;

export const ContentTicket = styled.div`
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1rem;
    height: 27rem;
    margin-bottom: 0.7rem;

    @media (max-width: 1050px) {
        width: 100%;
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        height: auto;
    }
`;

export const ImageTicket = styled.div`
    height: 100%;
    width: fit-content;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 5px;

    @media (max-width: 1000px) {
        margin-top: 1rem;
        height: auto;
        width: 100%;
        overflow: hidden;
        max-height: 100%;
    }
`;

export const ContainerInformations = styled.div`
    display: flex;
    width: 50%;

    flex-direction: column;
    padding-left: 1rem;
    justify-content: space-between;
    min-width: 17rem;

    @media (max-width: 1000px) {
        width: 50%;
    }

    div.texts {
        display: flex;
        flex-direction: column;
    }

    div.infos {
        display: flex;
        flex-direction: column;
    }

    div.numbers-of-sold {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 700px) {
        width: 100%;
        padding-left: 0;
    }

    span {
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: bold;
    }
`;

export const ContainerInformationsClosedCampaign = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.8rem;
    margin-top: -10rem;
    font-weight: 800;

    > div {
        margin-top: 1rem;
        width: 50vw;
    }

    span {
        font-size: 1.5rem; /* Definindo uma fonte menor para o span */
    }

    @media (max-width: 700px) {
        margin-top: -1rem;
    }

    @media (max-width: 375px) {
        margin-top: 3rem;
    }
`;

export const ContainerTitleTicket = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    margin-bottom: 1rem;
`;

export const Status = styled.span`
    font-size: 1.5rem !important;
    font-weight: bold;
    color: ${props => props.theme.color};
`;

export const TicketTitle = styled.h2`
    font-size: 1.8rem;
    margin-top: 1rem;
    font-weight: 800;
    text-decoration: underline;
`;

export const NumberOfPrizeDraw = styled.span`
    font-size: 1.5rem !important;
    font-weight: bold;
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 2.5rem;
    background-color: var(--light-gray);
    border-radius: 5px;
    border: dashed 2px var(--primary);
`;

export const ProgressBarValue = styled.div`
    height: calc(100% + 5px);
    margin-left: -3px;
    margin-top: -3px;
    background-color: var(--primary);
    border-radius: 5px;
    width: ${props => props.theme.value};
    transition: 1s ease;
    transition-delay: 0.5s;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.5rem;
    max-width: 100%;

    :hover {
        span {
            opacity: 1;
            transition: all 200ms;
            z-index: 2;
        }
    }

    span {
        color: black;
        font-size: 1rem;
        opacity: 0;
        cursor: default;
    }
`;

export const ContainerHowItWorks = styled.div`
    width: 100%;
    display: flex;

    @media (max-width: 700px) {
        flex-direction: column;
    }

    h2 {
        font-size: 2rem;
        color: var(--graphite);
        margin-top: 2rem;

        @media (max-width: 700px) {
            flex-direction: column;
            margin-bottom: 1rem;
        }
    }
`;

export const ContainerSteps = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: var(--light-gray);
    padding: 2rem 0;
    @media (max-width: 700px) {
        width: 100%;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    padding: 10px;

    h2 {
        color: var(--darkGreen);
        font-size: 1.8rem;
    }

    @media (max-width: 400px) {
        width: 90%;
        margin-bottom: 1.5rem;
    }

    img {
        height: 3rem;
        /* margin-bottom: 1rem; */

        @media (max-width: 900px) {
            height: 3rem;
        }
    }

    span {
        color: var(--black);
        font-size: 1rem;
        text-align: center;
        font-weight: 500;
    }
`;

export const ContainerPayment = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    background-color: var(--blackLight);

    span {
        color: var(--white);
        font-size: 1.3rem;
    }

    input {
        background-color: var(--white);
        color: var(--black);
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const ContainerPurchaseSummary = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    @media (max-width: 1000px) {
        width: 100%;
        margin-bottom: 2rem;
    }

    p {
        font-size: 1.5rem;
        color: var(--graphite);
        margin-bottom: 2rem;
    }

    span.salesman {
        font-weight: bold;
        font-size: 1.6rem;
        color: var(--primary);
    }
`;

export const PurchaseTitle = styled.h1`
    font-size: 2rem;
    color: var(--primary);
    width: 100%;
    text-align: center;
    margin: 1rem 0 2rem;
`;

export const ContainerNumberOfTickets = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    p {
        color: #fff;
        margin: auto 0;
        font-weight: bold;
        text-decoration: underline;
    }

    span {
        margin-right: 1rem;
        margin-bottom: 0;
    }

    span.note-about-tickets {
        background-color: var(--light-gray);
        padding: 1rem;
        border-radius: 5px;
        box-shadow: 0px 3px 8px 3px rgba(0, 0, 0, 0.3);
        font-size: 1rem;
        position: absolute;
        width: 15rem;
        display: ${props => props.theme.display};
        color: black;
    }

    .help {
        display: flex;
        align-items: center;
        width: 7rem;
        justify-content: space-between;
    }

    div {
        width: 4rem;
        height: 2rem;
    }
`;

export const TotalValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        margin-right: 15px;
    }

    span.value {
        margin-right: 0;
        font-size: 1.5rem;
        color: var(--primary);
    }
    span.fees {
        margin-right: 0;
        font-size: 1.1rem;
        color: var(--primary);
        margin-left: 10px;
    }
`;

export const BuyerInformation = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 2rem;

    h2 {
        font-size: 2rem;
        color: var(--primary);
        margin-bottom: 1.5rem;
    }

    div.container-input {
        width: 100%;
    }
`;

export const ContainerRegulament = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    align-self: center;
    margin-top: 2rem;
    align-items: center;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: var(--white);
    }

    span {
        font-size: 1.2rem;
        color: var(--white);
        text-align: center;
    }
`;

export const ContainerCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: flex-start;
    width: 100%;
    input {
    }

    span {
        label {
            font-size: 1.1rem;
            margin-bottom: 0;
            margin-right: 10px;
            color: var(--white);
        }
    }

    button.color {
        color: #6bbf1d;
        background-color: transparent;
        border: none;
    }
`;

export const InputPolicyTerms = styled.input<PropsPolicyTerms>`
    margin-right: 0.5rem;
    border: ${props => (props.isError ? 'solid 1px red' : 'none')};
`;

export const ContainerPaymentMethod = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;

    @media (max-width: 1000px) {
        width: 100%;
        margin-bottom: 2rem;
    }

    p {
        font-size: 1.5rem;
        color: var(--graphite);
        margin-bottom: 2rem;
    }

    div.container-input {
        width: 100%;
    }
`;

export const ContainerSelectPaymentMethod = styled.div`
    display: flex;

    input {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }

    span {
        font-weight: bold;
    }
`;

export const Select = styled.select`
    width: 100%;
    border-radius: 5px;
    border: 2px solid var(--graphite);
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    color: var(--graphite);
    margin-bottom: 2rem;

    option {
        color: var(--graphite);
        font-size: 1.2rem;
        padding: 0.5rem 1rem;
    }
`;

export const ContainerValityCvv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const ContainerValidity = styled.div`
    display: flex;
    flex-direction: column;

    p {
        font-size: 1.4rem;
        margin-bottom: 0.2rem;
        color: var(--white);
    }

    div {
        display: flex;
        select {
            width: 7rem;
            border-radius: 5px;
            border: 2px solid var(--graphite);
            padding: 0.5rem 1rem;
            font-size: 1.2rem;
            color: var(--graphite);
            margin-bottom: 2rem;
            background-color: var(--white);

            :first-child {
                margin-right: 1rem;
                width: 5rem;
            }

            option {
                color: var(--graphite);
                font-size: 1.2rem;
                padding: 0.5rem 1rem;
            }
        }
    }
`;

export const ContainerCvv = styled(ContainerValidity)`
    div.container-input {
        width: 8rem;

        input {
            padding: 0.5rem;
        }
    }
`;

export const ContainerButton = styled.div`
    display: flex;
    width: 100%;
    margin: 2rem 0 1rem;
    justify-content: center;

    button span {
        color: #000 !important;
    }
`;

export const PrizeDrawImage = styled.img`
    height: 100%;
    background-size: contain !important;
    border-radius: 5px;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.6);
`;

export const PrizeDrawName = styled.h3`
    color: var(--white);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
`;

export const ContainerTicketInformations = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 1000px) {
        width: 100%;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const Error = styled.div`
    width: 100vw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem;

    h1 {
        font-size: 2.5rem;
        text-align: center;
    }

    @media (max-width: 375px) {
        height: 90vh;
    }
`;

export const Subtitle = styled.h3`
    color: var(--white);
    font-size: 1.2rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
`;

export const BottomBar = styled.div`
    width: 85%;
    height: 2px;
    background-color: var(--primary);
    margin: 2rem 0;

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const ChoosePaymentMethods = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;

    img {
        width: 128px;
    }

    label {
        width: 100%;
        font-size: 16px;
        box-sizing: border-box;
        background-color: var(--white);
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        gap: 1rem;

        input[type='radio'] {
            width: 20px;
            height: 20px;
        }
    }
`;
