import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(156, 246, 171, 1) 62%, rgba(53, 236, 84, 1) 100%);
    @media (max-width: 620px) {
        padding: 0;
    }
`;

export const Header = styled.div`
    background-color: var(--black);
    width: 100vw;
    padding: 1.5rem;
    display: flex;
    justify-content: center;

    img {
        height: 4rem;
    }
`;

export const Title = styled.h1`
    font-size: 3rem;
    text-align: center;
`;

export const ContainerInputs = styled.div`
    padding: 4rem;
    width: 40rem;
    height: auto;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 650px) {
        width: 90%;
        padding: 4rem 1rem;
    }
`;

export const Input = styled.input`
    width: 100%;
    border-radius: 5px;
    border: solid 2px var(--graphite);
    padding: 0.7rem 1rem;
    font-size: 1.3rem;
    color: var(--graphite);

    ::placeholder {
        color: var(--dark-gray);
        font-size: 1.3rem;
    }
`;

export const ContainerInputPassword = styled.div`
    display: flex;
    width: 80rem;
    justify-content: space-between;

    @media (max-width: 1250px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`;

export const ContainerInput = styled.div`
    width: 100%;
    margin: 0 2.5rem 2rem;
    margin-bottom: 0;
`;

export const ContainerButtonSave = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    button {
        width: 7rem;
        margin-left: 1rem;
    }

    @media (max-width: 1250px) {
        justify-content: center;
    }
`;

export const ButtonSave = styled.button`
    background-color: var(--green);
    border: none;
    padding: 1rem;
    border-radius: 5px;
    width: 12rem;
    align-self: flex-end;

    span {
        color: var(--light-gray);
        font-size: 1.5rem;
    }
`;

export const HeaderLogin = styled.div`
    display: flex;
    align-items: center;
    margin: 2rem;
    margin-bottom: 0;
    div.text {
        display: flex;
        flex-direction: column;

        ul li {
            font-size: 1.3rem;
        }
    }
`;

export const Image = styled.img`
    margin-right: 2rem;

    @media (max-width: 1000px) {
        width: 7rem;
    }

    @media (max-width: 480px) {
        display: none;
    }
`;

export const Footer = styled.footer`
    background-color: var(--primary);
    width: 100vw;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 2rem;
    }

    span {
        font-weight: bold;
        margin-top: 0.5rem;
    }
`;
