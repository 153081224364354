import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { updateUserValidator } from 'validators/updateUser';
import getValidationErrors from 'utils/getValidationErrors';
import * as Yup from 'yup';
import { Container, ContainerButton, ContainerInputs, Content, Title } from './styles';

interface IProps {
    organizerName: string;
    organizerId: string;
}

interface IOrganizer {
    id: string;
    name: string;
    phone: string;
    document: string;
    nickname: string;
    email: string;
    active: boolean;
}

export function OrganizerDetails(): JSX.Element {
    const { state } = useLocation<IProps>();
    const [disableInputs, setDisableInputs] = useState(true);
    const { addError, addSuccess } = useToast();
    const [errors, setErrors] = useState({});
    const [organizer, setOrganizer] = useState<IOrganizer>({} as IOrganizer);

    useEffect(() => {
        async function handleGetUserData() {
            try {
                const response = await api.get(`organizers/${state.organizerId}`);
                setOrganizer({
                    id: response.data.data.id,
                    name: response.data.data.name,
                    phone: response.data.data.phone || '',
                    document: response.data.data.documentNumber,
                    nickname: response.data.data.nickname || '',
                    email: response.data.data.email,
                    active: response.data.data.active || '',
                });
            } catch (error) {
                addError('Erro ao obter dados do usuário');
            }
        }
        handleGetUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleSaveChanges() {
        try {
            setErrors({});
            const { name, phone, document, nickname, email, active } = organizer;
            const cleanedDocument = document.replace(/\D/g, '');
            const cleanedPhone = phone.replace(/\D/g, '');
            await updateUserValidator.validate(
                {
                    name,
                    email,
                    nickname,
                    document: cleanedDocument,
                    phone: cleanedPhone,
                },
                { abortEarly: false }
            );

            const data = {
                name,
                phone,
                document: cleanedDocument,
                nickname,
                email,
                active,
            };

            try {
                await api.patch(`organizers/${organizer.id}`, data);
                addSuccess('Dados atualizados');
                setDisableInputs(true);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                addError('Não foi possível atualizar os dados');
                if (error.response.data.error.message) addError(error.response.data.error.message);
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
            }
            addError('Não foi possível atuaizar os dados');
        }
    }

    return (
        <Container>
            <Content>
                <Title>Organizador(a): {organizer.name}</Title>
                <ContainerInputs>
                    <div className="container-input">
                        <Input
                            disabled={disableInputs}
                            name="name"
                            placeholder="Nome completo"
                            value={organizer.name || ''}
                            errors={errors}
                            onChange={e => setOrganizer({ ...organizer, name: e.target.value })}
                        />
                    </div>
                    <div className="container-input">
                        <Input
                            disabled={disableInputs}
                            name="nickname"
                            placeholder="Apelido"
                            value={organizer.nickname || ''}
                            errors={errors}
                            onChange={e => setOrganizer({ ...organizer, nickname: e.target.value })}
                        />
                    </div>
                    <div className="container-input">
                        <Input
                            disabled={disableInputs}
                            name="email"
                            placeholder="Email"
                            value={organizer.email || ''}
                            errors={errors}
                            onChange={e => setOrganizer({ ...organizer, email: e.target.value })}
                        />
                    </div>
                    <div className="container-input">
                        <Input
                            disabled={disableInputs}
                            name="document"
                            placeholder="document"
                            type="masked"
                            mask="999.999.999-99"
                            value={organizer.document || ''}
                            errors={errors}
                            onChange={e => setOrganizer({ ...organizer, document: e.target.value })}
                        />
                    </div>
                    <div className="container-input">
                        <Input
                            disabled={disableInputs}
                            name="phone"
                            placeholder="Celular"
                            type="masked"
                            mask="(99) 99999-9999"
                            value={organizer.phone || ''}
                            errors={errors}
                            onChange={e => setOrganizer({ ...organizer, phone: e.target.value })}
                        />
                    </div>
                    <div className="container-input">
                        <input
                            disabled={disableInputs}
                            className="checkbox"
                            name="active"
                            id="active"
                            type="checkbox"
                            checked={organizer.active || false}
                            onChange={e => setOrganizer({ ...organizer, active: e.target.checked })}
                        />
                        <label htmlFor="active">Ativo</label>
                    </div>
                </ContainerInputs>
                <ContainerButton>
                    {disableInputs ? (
                        <Button color="purple" onClick={() => setDisableInputs(!disableInputs)}>
                            Alterar cadastro
                        </Button>
                    ) : (
                        <Button color="green" onClick={() => handleSaveChanges()}>
                            Salvar
                        </Button>
                    )}
                </ContainerButton>
            </Content>
        </Container>
    );
}
