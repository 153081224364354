import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

export const signUpValidator = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    nick: Yup.string().required('Campo obrigatório'),
    telephone: Yup.string().required('Campo obrigatório'),
    email: Yup.string().required('Campo obrigatório').email('Endereço de email inválido'),
    document: Yup.string()
        .test('validate-cpf-cpnj', 'CPF ou CNPJ inválido', value => {
            if (value && (cpf.isValid(value) || cnpj.isValid(value))) return true;
            return false;
        })
        .required('Campo obrigatório'),
    password: Yup.string()
        .required('Campo obrigatório')
        .min(8, 'Deve ter pelo menos oito caracteres')
        .matches(
            /^(?=\D*\d)(?=[^A-Za-z]*[A-Za-z])[\w#?!@$%^&*-]{8,48}$/,
            'A senha precisa ter pelo menos 8 caracteres, letras e números'
        ),
    confirmPassword: Yup.string()
        .required('Campo obrigatório')
        .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais'),
});
