// componente de mostrar um icone de loading no meio da tela

import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Container } from './styles';

export function OverlayLoading(): JSX.Element {
    return (
        <Container>
            <div className="loading-overlay">
                <FaSpinner className="loading-spinner" size={50} color="green" />
            </div>
        </Container>
    );
}
