import { ReactNode } from 'react';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Container, Content } from './styles';

interface IProps {
    children?: ReactNode;
}

export function DefaultPage({ children, ...rest }: IProps): JSX.Element {
    return (
        <Container {...rest}>
            <Header />
            <Content>{children}</Content>
            <Footer />
        </Container>
    );
}
