import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';

import { TOKEN } from 'constants/environment';

let url = 'https://api.irifei.com.br/';
// let url = "";
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('homolog')) {
    url = `${process.env.REACT_APP_API_ENDPOINT}`;
}

const instance: AxiosInstance = axios.create({
    baseURL: url,
});

instance.interceptors.request.use(
    async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        const configCopy = config;
        const token = localStorage.getItem(TOKEN);

        if (token) {
            configCopy.headers.authorization = `Bearer ${token}`;
        }

        return configCopy;
    },
    (error: AxiosError) => {
        Promise.reject(error);
    }
);

export default instance;
