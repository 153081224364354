import { Container, Content, Image, CloseButton, ContainerTitle } from './styles';

interface IProps {
    showOverlay: () => void;
    image: string;
}

export function OverlayViewImage({ image, showOverlay }: IProps): JSX.Element {
    return (
        <Container>
            <Content>
                <ContainerTitle>
                    <CloseButton fill="#f00" size={30} onClick={() => showOverlay()} />
                </ContainerTitle>
                <Image src={image} alt="" />
            </Content>
        </Container>
    );
}
