import { Button } from 'components/Button';
import { RiWhatsappLine } from 'react-icons/ri';
import { useState } from 'react';
import { Container, Content, CloseButton, ContainerButtons, ContainerTitle } from './styles';

interface IProps {
    showOverlay: () => void;
    publishCampaign: (id: string) => Promise<void>;
    campaignId: string;
}

export function OverlayPublishCampaign({ showOverlay, campaignId, publishCampaign }: IProps): JSX.Element {
    const [isClicked, setIsClicked] = useState(false);
    return (
        <Container>
            <Content>
                <ContainerTitle>
                    <div className="text">
                        <h1>Tem certeza que deseja publicar a campanha? </h1>
                        <br />
                        <ul>
                            <li>
                                Após essa ação não será possível alterar a quantidade máxima de bilhetes e a quantidade
                                de bilhetes dos associados já cadastrados.
                            </li>
                            <br />
                            <li>
                                Por padrão a campanha tem a taxa de conveniência de 10% cobrada de quem compra bilhetes.
                                É possível que o fundo ou unidade arque com essa taxa, caso precise desse ajuste, entre
                                em contato com o nosso Atendimento antes de publicar a campanha:
                                <a href="https://bit.ly/contatoirifei">
                                    {'\n'}
                                    <RiWhatsappLine fill="#008000" />
                                    {'\n '}
                                    clique aqui
                                </a>
                                .
                            </li>
                            <br />
                            <li>
                                Ao publicar uma campanha, todos os associados receberão um e-mail com o link
                                personalizado da campanha.{' '}
                            </li>
                        </ul>
                    </div>
                    <CloseButton fill="#f00" size={30} onClick={() => showOverlay()} />
                </ContainerTitle>

                <ContainerButtons>
                    <Button color="red" onClick={() => showOverlay()}>
                        Cancelar
                    </Button>
                    <Button
                        color="green"
                        onClick={() => {
                            publishCampaign(campaignId);
                            setIsClicked(true);
                        }}
                        disabled={isClicked}
                    >
                        Publicar
                    </Button>
                </ContainerButtons>
            </Content>
        </Container>
    );
}
