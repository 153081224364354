import styled, { css } from 'styled-components';
import Masked from 'react-input-mask';
import Currency from 'react-currency-input-field';
import { colors } from 'styles/colors';

interface InputProps {
    isFocused: boolean;
    isErrored: boolean;
    readOnly: boolean;
}

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
`;

export const Container = styled.div<InputProps>`
    display: flex;
    flex-direction: column;
    input {
        background: ${props => (!props.readOnly ? '#fff' : 'rgba(256, 256, 256, 0.5)')}!important;
        position: relative;
        border-style: solid;

        border-width: ${props => (props.isFocused || props.isErrored ? '2px' : '2px')};

        ${props => {
            if (props.isFocused)
                return css`
                    border-color: var(--graphite);
                    box-shadow: 0 2px 5px ${`${colors.graphite}99`};
                `;
            if (props.isErrored)
                return css`
                    border-color: var(--danger);
                `;
            return css``;
        }}

        width: 100%;
        height: 2.64rem;
        border: 2px solid #4d4d4d;
        border-radius: 8px;
    }
`;

const textInputDefaultStyle = css`
    padding-left: 1.5rem;
    font-size: 1.2rem;
    color: var(--graphite);
    height: 100%;
    width: 100%;
    background: transparent;
    border: 0;
`;

export const TextInput = styled.input`
    ${textInputDefaultStyle};

    ::placeholder {
        color: var(--dark-gray);
    }
`;

export const TextInputMask = styled(Masked)`
    ${textInputDefaultStyle};

    ::placeholder {
        color: var(--dark-gray);
    }
`;

export const CurrencyInput = styled(Currency)`
    ::placeholder {
        color: var(--dark-gray);
    }

    ${textInputDefaultStyle};
`;

export const ErrorMessage = styled.span`
    font-size: 0.8rem !important;
    color: ${colors.danger}!important;
`;

export const Label = styled.span`
    font-size: 1.2rem;
    color: var(--black);
    padding-left: 5px;
    margin-bottom: 0.5rem;
`;
