import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useCallback, useEffect, useState } from 'react';
import { useToast } from 'hooks/toast';
import getValidationErrors from 'utils/getValidationErrors';
import * as Yup from 'yup';
import api from 'services/api';
import { campaignFeesValidator } from 'validators/campaignFees';
import {
    Container,
    ContainerButtons,
    ContainerInformartion,
    ContainerOthersFees,
    Content,
    InputsGrid,
    IrifeiFees,
} from './styles';

interface ICampaignFees {
    platformFee: string;
    paternFee: string;
    mastercard: string;
    visa: string;
    elo: string;
    irifeiFee: string;
    paternExpense: string;
    type: string;
}

interface IProps {
    handleCloseOverlay: () => void;
    campaignId: string;
}

export function OverlayCampaignFees({ handleCloseOverlay, campaignId }: IProps): JSX.Element {
    const [disableInputs, setDisableInputs] = useState(true);
    const { addSuccess, addError } = useToast();
    const [fees, setFees] = useState<ICampaignFees>({} as ICampaignFees);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFees({
            platformFee: '0',
            paternFee: '0',
            mastercard: '0',
            irifeiFee: '0',
            paternExpense: '0',
            elo: '0',
            visa: '0',
            type: 'REPASSADA',
        });
    }, []);

    const handleSaveChanges = useCallback(async () => {
        try {
            setErrors({});

            const { irifeiFee, platformFee, paternExpense, mastercard, elo, visa, paternFee, type } = fees;

            await campaignFeesValidator.validate(
                {
                    irifeiFee,
                    platformFee,
                    paternExpense,
                    mastercard,
                    elo,
                    visa,
                    paternFee,
                    type,
                },
                { abortEarly: false }
            );

            const form = {
                plataformBRL: parseFloat(platformFee.replace(',', '.')),
                partnerBRL: parseFloat(paternFee.replace(',', '.')),
                creditCardVisaPercentage: parseFloat(visa.replace(',', '.')),
                creditCardMastercardPercentage: parseFloat(mastercard.replace(',', '.')),
                creditCardEloPercentage: parseFloat(elo.replace(',', '.')),
                plataformPercentage: parseFloat(irifeiFee.replace(',', '.')),
                partnerValue: parseFloat(paternExpense.replace(',', '.')),
                type,
            };

            await api.put(`fees/${campaignId}`, form);

            addSuccess('Alterações salvas');
            setDisableInputs(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(error));
            }
            addError('Não foi possível atualizar as taxas');
            if (error.response?.data?.error?.message) {
                addError(error.response.data.error.message);
            }
        }
    }, [fees, addError, addSuccess, campaignId]);

    async function handleGetCampaignFees() {
        try {
            const response = await api.get(`fees/${campaignId}`);
            setFees({
                elo: response.data.data.creditCardEloPercentage,
                visa: response.data.data.creditCardVisaPercentage,
                mastercard: response.data.data.creditCardMastercardPercentage,
                platformFee: response.data.data.plataformBRL,
                paternFee: response.data.data.partnerBRL,
                irifeiFee: response.data.data.plataformPercentage,
                paternExpense: response.data.data.partnerValue,
                type: response.data.data.type,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            addError('Não foi possível carregar as taxas');
            if (error.response?.data?.error?.message) {
                addError(error.response.data.data.error.message);
            }
        }
    }

    useEffect(() => {
        handleGetCampaignFees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Content>
                <h1>Distribuição de taxas</h1>
                <InputsGrid>
                    <h3>Custo sobre venda (%)</h3>
                    <ContainerOthersFees>
                        <IrifeiFees>
                            <div className="container-input">
                                <span className="field-name">
                                    Taxa cobrada pela operadora de cartão bandeira Visa (%)
                                </span>
                                <Input
                                    disabled={disableInputs}
                                    name="visa"
                                    type="currency"
                                    value={fees.visa}
                                    errors={errors}
                                    onChange={e => setFees({ ...fees, visa: e.target.value })}
                                />
                            </div>
                            <div className="container-input">
                                <span className="field-name">
                                    Taxa cobrada pela operadora de cartão bandeira Mastercard (%)
                                </span>
                                <Input
                                    disabled={disableInputs}
                                    name="mastercard"
                                    type="number"
                                    value={fees.mastercard}
                                    errors={errors}
                                    onChange={e => setFees({ ...fees, mastercard: e.target.value })}
                                />
                            </div>
                            <div className="container-input">
                                <span className="field-name">
                                    Taxa cobrada pela operadora de cartão bandeira Elo (%)
                                </span>
                                <Input
                                    disabled={disableInputs}
                                    name="elo"
                                    type="number"
                                    value={fees.elo}
                                    errors={errors}
                                    onChange={e => setFees({ ...fees, elo: e.target.value })}
                                />
                            </div>
                        </IrifeiFees>
                        <div className="container-input">
                            <h3>Tipo de Taxa</h3>
                            <select
                                className="select-type-fees"
                                disabled={disableInputs}
                                placeholder="Tipo de Taxa"
                                value={fees.type}
                                onChange={e => {
                                    setFees(prevFees => ({
                                        ...prevFees,
                                        type: e.target.value,
                                        paternExpense:
                                            e.target.value === 'REPASSADA' || e.target.value === 'ABSORVIDA'
                                                ? '0.00'
                                                : prevFees.paternExpense,
                                    }));
                                }}
                            >
                                <option value="REPASSADA">TAXA REPASSADA</option>
                                <option value="ABSORVIDA">TAXA ABSORVIDA</option>
                                <option value="VIVA">TAXA VIVA</option>
                            </select>
                        </div>
                        <IrifeiFees>
                            <div className="container-input">
                                <span className="field-name">* Taxa cobrada pelo iRifei (%)</span>
                                <Input
                                    disabled={disableInputs}
                                    name="irifeiFee"
                                    type="number"
                                    value={fees.irifeiFee}
                                    errors={errors}
                                    onChange={e => setFees({ ...fees, irifeiFee: e.target.value })}
                                />
                            </div>
                            <div className="container-input">
                                <span className="field-name">* Taxa cobrada pelo parceiro (R$)</span>
                                <Input
                                    disabled={fees.type !== 'VIVA' || disableInputs}
                                    name="paternExpense"
                                    type="number"
                                    value={fees.paternExpense}
                                    errors={errors}
                                    onChange={e => setFees({ ...fees, paternExpense: e.target.value })}
                                />
                            </div>
                        </IrifeiFees>
                    </ContainerOthersFees>
                </InputsGrid>
                <ContainerInformartion>
                    <span>
                        * Se o tipo for Repassada, a taxa cobrado pelo iRifei será somado ao valor da compra do cliente.
                    </span>
                    <br />
                    <span>* Para que a taxa não seja repassada ao comprador, basta manter os valores em 0,00.</span>
                </ContainerInformartion>
                <ContainerButtons>
                    {disableInputs ? (
                        <>
                            <Button color="red" onClick={() => handleCloseOverlay()}>
                                Voltar
                            </Button>
                            <Button color="green" onClick={() => setDisableInputs(false)}>
                                Editar
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button color="red" onClick={() => setDisableInputs(true)}>
                                Cancelar
                            </Button>
                            <Button color="green" onClick={() => handleSaveChanges()}>
                                Salvar
                            </Button>
                        </>
                    )}
                </ContainerButtons>
            </Content>
        </Container>
    );
}
