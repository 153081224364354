import { DefaultPage } from 'pages/App/_DefaultPage';
import styled from 'styled-components';

export const Container = styled(DefaultPage)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Content = styled.div`
    width: 45rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    border-radius: 10px;
    border: solid 2px var(--dark-gray);
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    background-color: var(--secondary);
    margin: 0 auto;
    margin-top: 2rem;

    @media (max-width: 750px) {
        width: 30rem;
    }
    @media (max-width: 450px) {
        width: 20rem;
        padding: 1rem;
    }

    h1 {
        width: 100%;
        text-align: center;
        margin-bottom: 1.5rem;
        font-size: 1.7rem;
    }
`;

export const ContainerButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    button {
        margin-right: 15px;
    }
`;
