import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { Button } from 'components/Button';
import {
    Container,
    ContainerTable,
    Content,
    Table,
    TicketError,
    TicketIcon,
    ContainerInfos,
    BottomBar,
} from './styles';

interface IUserTicket {
    number: string;
    status: string;
}

interface IProps {
    email: string;
}
export interface ICampaignsData {
    id: string;
    name: string;
    organizerName: string;
    status: string;
    prizeDrawDate: string;
    active: boolean;
    showTickets: boolean;
}

export function MyTicketsAffiliate(): JSX.Element {
    const [myTickets, setMyTickets] = useState<Array<IUserTicket>>([] as Array<IUserTicket>);
    const [campaignName, setCampaignName] = useState<string>('');
    const [campaigns, setCampaigns] = useState<Array<ICampaignsData>>([] as Array<ICampaignsData>);
    const location = useLocation<IProps>();
    const { addError } = useToast();
    const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);
    const [noEmailMessage, setNoEmailMessage] = useState(false);
    const [noTicketsMessage, setNoTicketsMessage] = useState(false);

    useEffect(() => {
        async function handleGetMyCampaigns() {
            try {
                const response = await api.get(`campaigns/by-affiliate-email/${location.state.email}`);
                const { data, count } = response.data;
                if (count === 0) {
                    setNoTicketsMessage(true);
                } else {
                    setCampaigns(data);
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                if (error.response && error.response.status === 404) {
                    setNoEmailMessage(true);
                } else {
                    addError('Erro ao carregar bilhetes');
                }
            }
        }
        handleGetMyCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleGetMyTickets(campaignId: string) {
        try {
            const response = await api.get(`purchases/email/${location.state.email}/${campaignId}`);
            setMyTickets(response.data.data.tickets);
        } catch (error) {
            addError('Erro ao carregar bilhetes');
        }
    }

    return (
        <Container>
            <Content>
                {noEmailMessage && (
                    <ContainerTable>
                        <TicketError>E-mail de associado não cadastrado no sistema. 😥</TicketError>
                    </ContainerTable>
                )}
                {noTicketsMessage && (
                    <ContainerTable>
                        <TicketError>
                            Os números dos bilhetes adquiridos foram enviados para o seu e-mail cadastrado juntamente
                            com o nome do comprador.
                        </TicketError>
                        <TicketError>
                            Caso não tenha recebido, entre em contato conosco em{' '}
                            <a href="mailto:falecomirifei@gmail.com">falecomirifei@gmail.com</a>.
                        </TicketError>
                    </ContainerTable>
                )}
                {campaigns.length > 0 && !noEmailMessage && !noTicketsMessage && (
                    <>
                        <ContainerInfos>
                            <h1> Escolha a campanha para visualizar seus bilhetes:</h1>
                        </ContainerInfos>
                        <ContainerInfos>
                            <div className="campaigns">
                                {campaigns.map(campaign => (
                                    <Button
                                        key={campaign.id}
                                        onClick={() => {
                                            handleGetMyTickets(campaign.id);
                                            setSelectedCampaignId(campaign.id);
                                            setCampaignName(campaign.name);
                                        }}
                                        className={selectedCampaignId === campaign.id ? 'selected' : ''}
                                    >
                                        {campaign.name}
                                    </Button>
                                ))}
                            </div>
                        </ContainerInfos>
                        {selectedCampaignId && (
                            <ContainerInfos>
                                <TicketIcon />
                                <div className="texts">
                                    <h1>Seus números da sorte</h1>
                                    <h3> Campanha: {campaignName}</h3>
                                    <ul>
                                        <li>Aqui você encontra seus números da sorte.</li>
                                        <li>
                                            Todos os bilhetes com o status de ‘disponível’ são aqueles que você concorre
                                            ao sorteio.
                                        </li>
                                    </ul>
                                    <BottomBar />
                                </div>
                            </ContainerInfos>
                        )}
                        {selectedCampaignId && (
                            <ContainerTable>
                                <Table>
                                    <thead>
                                        <tr>
                                            <td>Número do bilhete</td>
                                            <td>Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myTickets.map(obj => {
                                            return (
                                                <tr key={obj.number}>
                                                    <td>{obj.number}</td>
                                                    <td>{obj.status}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </ContainerTable>
                        )}
                    </>
                )}
            </Content>
        </Container>
    );
}
