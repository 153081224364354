import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

export const updateUserValidator = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    nickname: Yup.string().required('Campo obrigatório'),
    email: Yup.string().required('Campo obrigatório').email('Endereço de email inválido'),
    document: Yup.string()
        .test('validate-cpf-cpnj', 'CPF ou CNPJ inválido', value => {
            if (value && (cpf.isValid(value) || cnpj.isValid(value))) return true;
            return false;
        })
        .required('Campo obrigatório'),
    phone: Yup.string()
        .optional()
        .test('validate-phone', 'Número de telefone inválido', value => {
            if (value && (value.length < 11 || value.length > 12)) {
                return false;
            }
            if (value && !/^(\d{2})?-?\d{5}-?\d{4}$/g.test(value)) {
                return false;
            }
            return true;
        }),
});
