import { Container, Content, ConatinerRules, CloseButton, ContainerTitle } from './styles';

interface IProps {
    showOverlay: () => void;
}

export function OverlayTermsOfService({ showOverlay }: IProps): JSX.Element {
    return (
        <Container>
            <Content>
                <ConatinerRules>
                    <ContainerTitle>
                        <h1>Regulamento do iRifei</h1>
                        <CloseButton fill="#000" size={30} onClick={() => showOverlay()} />
                    </ContainerTitle>
                    <span>
                        1. SOBRE ESTE TERMO DE USO 1.1 Este Termo de Uso é um contrato aceito por você quando fizer uso
                        da plataforma iRifei, independentemente de qualquer outra forma de aceitação. 1.2 No uso da
                        plataforma iRifei, Você deve obedecer a legislação vigente e este Termo de Uso. 1.3 Você é
                        integralmente responsável por todos seus atos praticados no uso da plataforma iRifei. Da mesma
                        forma, você também é responsável pela veracidade das informações fornecidas. 1.4 A iRifei se
                        reserva o direito de recusar, bloquear, inabilitar ou excluir, a seu exclusivo critério,
                        usuários que descumpram qualquer uma das disposições presentes neste Termo de Uso. 1.5 Se você
                        tiver dúvidas a respeito de qualquer previsão neste Termo de Uso, entre em contato com nossa
                        equipe por meio do email falecomirifei@gmail.com. 2. SOBRE O SERVIÇO 2.1 A iRifei é uma
                        plataforma tecnológica desenvolvida para possibilitar a criação de campanhas de arrecadação com
                        fins beneficentes, ou para suportar causas sem fins lucrativos. 2.2 A iRifei, em hipótese
                        alguma, deve ser utilizada com fins comerciais, devendo seu uso ser restrito à arrecadação de
                        fundos para causas beneficentes ou sem fins lucrativos. 2.3 A iRifei não é uma plataforma de
                        jogos. O usuário arrecadador não deve criar campanhas com intenções iguais ou semelhantes a um
                        jogo de azar. Todo e qualquer pagamento efetuado pelo usuário doador ao usuário arrecadador
                        durante uma campanha de arrecadação é uma doação voluntária, não caracterizando relação
                        comercial ou de jogos de azar. Portanto, no momento da doação o usuário doador não deve criar
                        nenhuma expectativa de retorno financeiro na forma de produtos ou serviços. 2.4 É facultativo ao
                        usuário arrecadador oferecer um brinde como um símbolo de agradecimento pelo suporte na campanha
                        de arrecadação. 2.5 Uma vez que o usuário arrecadador decida pela distribuição de brindes, ele
                        se compromete a entregá-los nas condições informadas ao(s) usuário(s) doadores selecionados pelo
                        algoritmo tecnológico. 2.6 A iRifei não organiza nem gere campanhas. A responsabilidade pela
                        criação e gestão das campanhas é dos usuários organizadores, não cabendo à plataforma requerer
                        eventuais autorizações que o organizador entender necessárias às especificidades de sua
                        campanha. 2.7 A iRifei também não é um serviço comparável aos serviços financeiros oferecidos
                        por instituições bancárias ou administradoras de cartão de crédito, consistindo apenas em uma
                        forma de facilitação de arrecadação de fundos para causas beneficentes ou sem fins lucrativos,
                        mediante serviços de gestão de pagamento, por conta e ordem do usuário arrecadador. 3. CONDIÇÕES
                        GERAIS DE ACESSO E USO DA iRifei 3.1 Aceite. Você reconhece e concorda que a utilização da
                        plataforma iRifei implica na mais alta compreensão, aceitação e vinculação automática ao
                        presente Termo de Uso, independentemente de qualquer outro procedimento para aceitação. Caso
                        tenha alguma objeção a quaisquer itens, diretrizes, condições previstas neste Termo de Uso você
                        não deve fazer o uso da plataforma iRifei. 3.2 Responsabilidade. Você reconhece e aceita que o
                        uso da plataforma iRifei será feito por sua exclusiva conta, risco e responsabilidade, e se
                        compromete a utilizar a plataforma e todas as suas ferramentas de acordo com a legislação
                        vigente e este Termo de Uso, abstendo-se de utilizar a plataforma para realizar atividades
                        ilícitas, imorais ou que de qualquer forma atentem contra direitos de terceiros. 4. DA
                        PARTICIPAÇÃO NAS CAMPANHAS 4.1 A iRifei recomenda aos usuários que participem apenas de ações de
                        usuários que ele conheça e tenha uma relação mínima de confiança. A iRifei oferece a plataforma
                        como serviço, mas a criação e gestão das campanhas são responsabilidade dos organizadores. 4.2
                        Responsabilidade pelo pagamento. Você concorda e reconhece que ao apoiar qualquer campanha de
                        arrecadação, o pagamento foi realizado de forma livre, consciente e voluntária através do
                        contato direto com o usuário arrecadador. Portanto, você é o único responsável pela referida
                        doação, que será efetuada de acordo com as condições e formas de pagamento previstas ou
                        negociadas, sem prejuízo de outras obrigações assumidas com o usuário arrecadador. Por isso,
                        antes de efetuar uma doação, o doador deve procurar obter todas as informações junto ao
                        arrecadador e buscar averiguar a veracidade de tais informações, origem e procedência dos
                        produtos ou serviços, devendo avaliar por sua conta e risco a conveniência de prosseguir com a
                        transação. 4.3 Garantia iRifei. A iRifei poderá suspender, reverter, ou cancelar créditos da
                        conta do usuário arrecadador e revertê-los aos usuários doadores caso o usuário arrecadador
                        preste informações falsas ou não entregue eventuais brindes prometidos na quantidade, modelo e
                        dentro prazo convencionados. 4.4 Os usuários doadores da campanha de arrecadação, se sorteados
                        para receberem brindes, serão notificados pela iRifei e terão até 07 dias para se manifestar
                        sobre o recebimento. Se o ganhador não se manifestar nesse prazo, o status da campanha será
                        alterado automaticamente para disputa aberta, abrindo-se o período para resolução da
                        divergência, que pode contar com a intermediação da iRifei. 4.5 Após 1 mês da data do sorteio,
                        as campanhas com status disputa aberta sem resolução da divergência serão canceladas. A Garantia
                        iRifei será aplicada e os valores devolvidos aos doadores da campanha. 5. DAS PROIBIÇÕES 5.1 Não
                        é permitido aos usuários utilizarem contas fakes/falsas para criar ações com o intuito de
                        ludibriar, enganar ou criar falsas expectativas aos demais usuários. 5.2 Não são permitidas
                        ações que prometam oferecer os seguintes itens: serviços, produtos e conteúdo sexualmente
                        explícito; explosivos; armas de fogo; medicamentos; produtos químicos de potencial
                        periculosidade; e quaisquer outros que a administração julgue inapropriado, ofensivo ou em
                        contradição com estes termos e as leis vigentes. 5.3 Você se compromete a não transmitir,
                        introduzir, difundir ou colocar à disposição de terceiros qualquer tipo de material ou
                        informação (dados de conteúdos, mensagens, desenhos, arquivos de som e imagem, fotografias,
                        software, etc.) que sejam contrários à legislação vigente, à moral, à ordem pública, e ao
                        presente Termo de Uso. 5.4 Não é permitido aos utilizadores postar informações de cunho
                        preconceituoso, ofensivo, violento nas descrições das ações. 6. DIREITOS DA iRifei 6.1 A iRifei
                        poderá remover, a seu exclusivo critério, as campanhas que não estejam de acordo com este Termo
                        de Uso e as leis vigentes. Não obstante a remoção da campanha, a iRifei se reserva ainda o
                        direito de exclusão ou inabilitação do Usuário infrator. 6.2 Validação. No sentido de melhor
                        atender requisitos de transparência e zelar pelo bom funcionamento e segurança das relações
                        entre os usuários da plataforma, a iRifei poderá, a seu critério, verificar a validade de dados
                        de contato fornecidos, como telefone e e-mail. Esta verificação não acarreta em qualquer
                        compromisso, garantia, obrigação ou responsabilidade da iRifei quanto à veracidade destas
                        informações, que continuam sendo de integral responsabilidade do Usuário que as disponibilizou.
                        6.3 Disponibilidade de informações. A iRifei não se obriga a disponibilizar aos Usuários o
                        backup de campanhas cujos prazos de disponibilidade já expiraram ou foram desativados por
                        qualquer motivo, incluindo por ação própria do Usuário. 7. TRANSFERÊNCIA DOS VALORES ARRECADADOS
                        7.1 Após o sorteio, a iRifei encaminhará um email ao sorteado na campanha com um link onde
                        ele(a) deverá clicar para confirmar o recebimento do brinde. Assim que a iRifei receber essa
                        confirmação, o valor arrecadado será liberado para pagamento. 7.2 O ganhador terá até 07 dias
                        para se manifestar sobre o recebimento do brinde ou sobre alguma irregularidade. Se o ganhador
                        não se manifestar nesse prazo, o status da campanha será alterado automaticamente para disputa
                        aberta, abrindo-se o período para resolução da divergência, que pode contar com a intermediação
                        da iRifei. 8. POLÍTICA DE PRIVACIDADE 8.1 A iRifei garante a confidencialidade das informações
                        fornecidas pelos usuários, exceto aquelas que o próprio usuário optar por divulgar em suas
                        campanhas, assim como as necessárias para cumprimento deste Termo de Uso e de obrigações legais.
                        Todavia, o usuário está ciente de que, nos termos da Lei em vigor, tais informações, bem como
                        quaisquer outros dados coletados durante o uso da plataforma, poderão ser divulgadas para fins
                        de investigações de eventuais ilícitos cometidos. 9. GARANTIAS E RESPONSABILIDADE 9.1
                        Disponibilidade do serviço. A plataforma depende de muitos fatores alheios ao controle da
                        iRifei, como qualidade da internet do Usuário, configurações do dispositivo utilizado para
                        acessá-la, e disponibilidade técnica dos parceiros de processamento de pagamentos e de
                        infraestrutura tecnológica. Dessa forma, a iRifei não garante a disponibilidade ininterrupta do
                        funcionamento da plataforma ou de suas funcionalidades, não sendo responsável por nenhum dano ou
                        prejuízo causado a você durante ou devido ao período de indisponibilidade, tais como doações
                        frustradas e impedimento de sorteio o sorteio manual. Todavia garante que todas as informações
                        recebidas durante o período de disponibilidade, como dados de doações, são preservados por
                        rotinas constantes de backup. 9.2 Conduta dos Usuários. A iRifei não garante que os usuários da
                        plataforma a utilizam em conformidade com a lei, a moral, a ordem pública, e o presente Termo de
                        Compromisso. Do mesmo modo, não garante a veracidade e exatidão, esgotamento ou autenticidade
                        dos dados disponibilizados pelos usuários nas páginas de campanha, não sendo responsável por
                        infrações de usuários nesse sentido. A iRifei não será responsável também por falsificação de
                        identidade por Usuários. O mau uso da plataforma é de responsabilidade do usuário. 9.3
                        Desvinculação com os usuários. A iRifei não atua como prestadora de serviços de consultoria ou
                        intermediação entre os usuários da plataforma. A iRifei disponibiliza ferramenta tecnológica
                        para que os usuários criem campanhas de arrecadação. Assim, a iRifei não é responsável por danos
                        e prejuízos oriundos dos negócios entre usuários, sendo estes responsáveis pelas próprias ações
                        ou omissões, bem como por quaisquer imprecisões, erros e fraudes que possam advir das
                        negociações. Anúncios de terceiros que conduzam a plataforma a erro também não são
                        responsabilidade da iRifei, e assim, qualquer reclamação ou ação legal que venha a ser
                        necessária deve ser direcionada ao usuário ou ao terceiro que gerou o dano ou prejuízo. 9.4
                        Responsabilidade Tributária. Você é responsável pelas obrigações tributárias decorrentes de sua
                        campanha, respondendo a iRifei apenas pelas atividades desenvolvidas por si própria, observados
                        os limites legais. 9.5 Proteções. Você reconhece que estão protegidos por direitos de
                        propriedade intelectual da iRifei ou de terceiros a totalidade: - dos elementos da plataforma e
                        de cada uma de suas ferramentas; - das informações e conteúdos materiais da plataforma; - das
                        estruturas, seleções, ordenações e apresentações dos conteúdos da plataforma; e - dos programas
                        operacionais utilizados pela plataforma. 10. DISPOSIÇÕES GERAIS 10.1 Modificações. Este Termo de
                        Uso rege a relação entre a iRifei e você. A iRifei poderá modificar este Termo de Uso e demais
                        políticas aplicáveis sempre que for necessário refletir alterações da lei ou mudanças em suas
                        ferramentas. Você deverá consultar regularmente o Termo de Uso e demais políticas aplicáveis. A
                        plataforma poderá postar avisos informando a existência de modificações neste Termo de Uso nesta
                        página. Havendo alterações das utilidades, ferramentas e de novas funcionalidades, tais
                        alterações entrarão em vigor imediatamente, sendo responsabilidade do usuário a verificação das
                        condições aplicáveis no momento da utilização da plataforma. Se você não concordar com os termos
                        alterados, você deverá descontinuar o uso da plataforma. 10.2 Direito de exclusão, solicitação
                        de documentos, e cancelamento de campanhas. A iRifei se reserva o direito de recusar ou retirar
                        o acesso à plataforma, inabilitar o cadastro, ou cancelar campanhas, sem necessidade de aviso
                        prévio, daqueles usuários que descumpram este Termo de Uso ou àqueles que a iRifei, por
                        liberalidade própria, resolver recusar ou retirar da plataforma. A iRifei poderá ainda, a seu
                        critério, solicitar documentos e informações complementares quando entender necessário para
                        manutenção da conta de um usuário ou da campanha de arrecadação. 10.3 Em caso de remoção de
                        conteúdo e/ou suspensão ou cancelamento de cadastro de usuário por desrespeito ao presente Termo
                        de Uso, a campanha será cancelada e eventuais valores arrecadados serão devolvidos aos usuários
                        doadores. 10.4 Menores de idade. O uso das facilidades da plataforma por menores de idade só
                        poderá ocorrer mediante prévia autorização de seus responsáveis legais, sendo o menor
                        devidamente assistido e supervisionado pelos mesmos, que serão os responsáveis por todos os atos
                        praticados pelos menores através da plataforma. Nos casos em que expressamente se assinale, o
                        acesso ficará restringido única e exclusivamente aos maiores de 18 anos. Na ocorrência de causas
                        que afetam a capacidade civil do Usuário, o uso da plataforma lhe será restrito sob as mesmas
                        condições dos menores de idade. 10.5 Duração e Término. A disponibilização das ferramentas e
                        funcionalidades da iRifei na plataforma tem duração indefinida, ressalvadas as disposições em
                        contrário relativas a funcionalidades específicas. Sem prejuízo das disposições anteriores, a
                        iRifei se reserva o direito de decidir sobre o encerramento, suspensão ou interrupção das
                        funcionalidades oferecidas, podendo fazê-lo, unilateralmente e a qualquer momento, sem a
                        obrigatoriedade de prévio aviso aos usuários, salvo se houver contraprestação financeira com
                        relação à funcionalidade alterada/cancelada. Nesta hipótese, a iRifei comunicará os usuários
                        através do endereço eletrônico cadastrado, com a antecedência mínima de 5 (cinco) dias sobre a
                        descontinuidade da ferramenta, de modo a garantir que os usuários estejam cientes do
                        encerramento, suspensão ou interrupção a ser efetuada. 10.6 Comunicações. Podemos enviar-lhe
                        informações em formato eletrônico, seja por e-mail (endereço especificado por você na
                        plataforma) ou demais meios eletrônicos disponíveis, como notificações via push e ainda por meio
                        físico por escrito, enviado por correios ao endereço indicado. Os avisos enviados a você via
                        e-mail serão considerados entregues e recebidos na data da transmissão do e-mail. Todas as
                        notificações à plataforma devem ser enviadas para o correio eletrônico falecomirifei@gmail.com.
                        Podemos ainda enviar-lhe comunicações com divulgações sobre novidades ou outras informações
                        sobre a iRifei e/ou sobre empresas do mesmo grupo econômico da iRifei. 10.7 Renúncia e autonomia
                        das disposições. Qualquer omissão ou tolerância na exigência do fiel cumprimento dos termos e
                        condições deste Termo de Uso, ou no exercício das prerrogativas dele decorrentes, não
                        constituirá novação ou renúncia, nem afetará o direito da iRifei em exercê-lo a qualquer tempo.
                        Outrossim, a renúncia a qualquer desses direitos somente será válida se formalizada por escrito,
                        bem como a nulidade ou invalidade de qualquer previsão deste Termo de Uso não prejudicarão a
                        validade e eficácia das demais disposições.
                    </span>
                </ConatinerRules>
            </Content>
        </Container>
    );
}
