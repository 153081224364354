import styled from 'styled-components';
import { GiHamburgerMenu } from 'react-icons/gi';

export const Container = styled.header`
    display: flex;
    align-items: center;
    height: 6rem;
    width: calc(100%);
    background-color: var(--black);
    padding: 0 2rem;
    z-index: 9;
    padding-left: 10rem;

    @media (max-width: 600px) {
        width: 100vw;
        padding-left: 0;
    }
    @media (max-width: 380px) {
        justify-content: space-between;
    }

    h1 {
        font-size: 3rem;
        color: var(--graphite);
        margin-right: 3rem;
        cursor: pointer;

        @media (max-width: 1300px) {
            font-size: 2.5rem;
        }

        @media (max-width: 800px) {
            display: none;
        }
    }

    .home {
        display: flex;
        text-decoration: none;
        align-items: center;
    }
`;

export const LogoImage = styled.img`
    height: 4rem;
    cursor: pointer;
    margin-right: 1rem;

    @media (max-width: 500px) {
        width: 10rem;
        height: 4rem;
        margin-right: 0.5rem;
    }
`;

export const WelcomeText = styled.h2`
    font-size: 2.5rem;
    background-color: var(--graphite);
    color: var(--white);
    font-weight: 300;
    width: 40rem;
    display: block;
    padding: 5px;
    border: dashed 2px var(--primary);
    border-radius: 3px;
    margin-top: 7px;
    width: fit-content;
    padding-right: 2rem;

    @media (max-width: 1300px) {
        font-size: 2rem;
    }

    @media (max-width: 600px) {
        font-size: 1.5rem;
    }

    @media (max-width: 380px) {
        display: none;
    }
`;

export const ContainerButtonsOrganizer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    height: 3rem;
    flex-direction: row;

    button.myProfile {
        width: 10rem;
    }

    @media (max-width: 1100px) {
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }

    @media (max-width: 380px) {
        align-items: flex-end;
    }
`;
export const ContainerButtonsAdmin = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    height: 3rem;
    flex-direction: row;
    height: auto;
    align-items: center;

    .button-admin {
        @media (max-width: 800px) {
            display: none;
        }
    }

    button.myProfile {
        width: 10rem;
    }

    @media (max-width: 380px) {
        align-items: flex-end;
    }
`;

export const Button = styled.button`
    background-color: var(--primary);
    border-radius: 5px;
    font-size: 1.3rem;
    color: var(--black);
    text-align: center;
    border: none;
    display: block;
    padding: 10px 15px;

    @media (max-width: 1100px) {
        margin-bottom: 0.5rem;
    }
    @media (max-width: 800px) {
        display: none;
    }
`;

export const OverlayHiddenMenuOrganizer = styled.div`
    width: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 5px;
    position: absolute;
    right: 2rem;
    top: 6rem;
    background-color: var(--primary);
    z-index: 9;
    border: solid 1px var(--graphite);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);

    display: ${props => props.theme.display};

    button {
        background-color: transparent;
        border-radius: 5px;
        font-size: 1.3rem;
        color: var(--black);
        font-weight: bold;
        text-align: center;
        border: none;
        width: 100%;
        margin-bottom: 1rem;
        display: block;

        :active {
            -webkit-box-shadow: -1px 1px 1px 0px #000000;
            box-shadow: -1px 1px 1px 0px #000000;
        }
    }
`;

export const HamburguerMenuButtonOrganizer = styled(GiHamburgerMenu)`
    fill: var(--primary);
    cursor: pointer;
    width: 40px;
    height: 40px;
`;

export const HamburguerMenuButtonAdmin = styled(HamburguerMenuButtonOrganizer)`
    display: none;
    @media (max-width: 800px) {
        display: block;
    }
`;

export const HamburguerMenuButtonClient = styled(GiHamburgerMenu)`
    display: none;
    fill: var(--graphite);
    cursor: pointer;
    width: 40px;
    height: 40px;

    @media (max-width: 1200px) {
        display: block;
    }
`;

export const ContainerButtonsClient = styled.nav`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 1200px) {
        display: none;
    }
`;

export const NavButton = styled.button`
    background: transparent;
    border: none;
    font-size: 1.2rem;
    font-style: italic;
    color: var(--white);
    margin: 0 1rem;
    text-decoration: none;
    display: block;
    cursor: pointer;

    @media (max-width: 800px) {
        margin: 1rem;
    }

    :hover {
        text-decoration: underline;
        color: var(--white);
    }
    a {
        background: transparent;
        border: none;
        font-size: 1.2rem;
        font-style: italic;
        color: var(--white);
        text-decoration: none;

        :hover {
            text-decoration: underline;
            color: var(--white);
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

export const OverlayHiddenMenuClient = styled(OverlayHiddenMenuOrganizer)`
    width: 15rem;
`;

export const ButtonAdmin = styled(Button)`
    @media (max-width: 800px) {
        display: block;
    }
`;

export const ContainerImageName = styled.div`
    display: flex;
    align-items: center;
`;
