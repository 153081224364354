import { USER } from 'constants/environment';
import { createContext, useContext, useEffect, useState } from 'react';

interface UserTypeContextData {
    userType?: 'CLIENT' | 'ORGANIZER' | 'ADMIN';
    handleGetUserType: () => 'CLIENT' | 'ORGANIZER' | 'ADMIN' | undefined;
}

interface IMenuProviderProps {
    children: JSX.Element | JSX.Element[];
}

const MenuContext = createContext({} as UserTypeContextData);

const UserTypeProvider = ({ children }: IMenuProviderProps): JSX.Element => {
    const [userType, setUserType] = useState<'CLIENT' | 'ORGANIZER' | 'ADMIN'>();

    useEffect(() => {
        const user = localStorage.getItem(USER);
        if (user) setUserType(JSON.parse(user).type);
        else {
            setUserType('ADMIN');
        }
    }, [userType]);

    function handleGetUserType() {
        return userType;
    }
    return <MenuContext.Provider value={{ handleGetUserType }}>{children}</MenuContext.Provider>;
};

function useUserType(): UserTypeContextData {
    const context = useContext(MenuContext);

    if (!context) {
        throw new Error('useUserType must be used within a userTypeProvider');
    }

    return context;
}

export { UserTypeProvider, useUserType };
