import { useToast } from 'hooks/toast';
import { FaCopy } from 'react-icons/fa';
import { copyToClipboard } from 'utils/copyToClipboard';
import { Counter } from './Counter';
import {
    Container,
    Subtitle,
    PixQrCodeContainer,
    PixCopyAndPaste,
    PixInstructionsBeforePaymentContainer,
    Text,
    CopyButton,
} from './styles';

type PixPaymentMethodProps = {
    pixQrCode?: string;
    paymentCompanyProvider?: string;
    pixExpirationTimeMilliseconds?: number;
};

export function PixPaymentMethod(props: PixPaymentMethodProps): JSX.Element {
    const { pixQrCode, paymentCompanyProvider, pixExpirationTimeMilliseconds } = props;
    const { addSuccess } = useToast();

    const onCopyPix = () => {
        addSuccess('Pix copiado com sucesso.');
    };

    return (
        <Container>
            {pixQrCode && (
                <>
                    <Subtitle>Já nessa parte leia o QR code abaixo para efetuar o pagamento via PIX</Subtitle>
                    {!!pixExpirationTimeMilliseconds && <Counter offsetMilliseconds={pixExpirationTimeMilliseconds} />}
                    <PixQrCodeContainer>
                        <img
                            src={`https://api.qrserver.com/v1/create-qr-code/?size=220x220&data=${pixQrCode}`}
                            alt="QR code do PIX"
                        />
                    </PixQrCodeContainer>
                    <Subtitle>
                        Ou se preferir utilize a opção copia e cola.
                        <br />
                        Lembre-se de selecionar a opção “Pix Copia e Cola” no aplicativo do seu banco.
                    </Subtitle>
                    <PixCopyAndPaste>
                        {pixQrCode}
                        <CopyButton onClick={() => copyToClipboard(pixQrCode, onCopyPix)}>
                            Clique aqui para copiar o Código PIX <FaCopy />
                        </CopyButton>
                    </PixCopyAndPaste>
                    <br />
                    {paymentCompanyProvider && (
                        <Subtitle>
                            Importante destacar que você verá o nome {paymentCompanyProvider} como destinatário. Ela é a
                            nossa parceira que possibilita realizar os pagamentos via PIX.
                        </Subtitle>
                    )}
                    <br />
                    <Text>
                        Caso já tenha efetuado o pagamento aguarde o recebimento do email de confirmação com os bilhetes
                        da sorte. Você também poderá acessar os bilhetes da sorte após a confirmação do pagamento{' '}
                        <a href="/consultar-bilhetes" target="_blank">
                            clicando aqui
                        </a>
                        .
                    </Text>
                </>
            )}

            {!pixQrCode && (
                <PixInstructionsBeforePaymentContainer>
                    Para finalizar a compra clique no botão abaixo. Você visualizará um QR Code em seguida para efetuar
                    o pagamento.
                    <br />
                    Esse QR Code é válido por <b>5 minutos</b>, depois desse período, será necessário gerar uma nova
                    compra.
                </PixInstructionsBeforePaymentContainer>
            )}
        </Container>
    );
}
