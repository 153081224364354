import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

export const buyTicketsWithCreditCardValidator = Yup.object().shape({
    buyerName: Yup.string().min(4, 'Digite um nome completo').required('Campo obrigatório'),
    buyerEmail: Yup.string().required('Campo obrigatório'),
    buyerTelephone: Yup.string().required('Campo obrigatório'),
    buyerCpf: Yup.string()
        .test('validate-cpf-cpnj', 'CPF inválido', value => {
            if (value && cpf.isValid(value)) return true;
            return false;
        })
        .required('Campo obrigatório'),
    cardName: Yup.string().required('Campo obrigatório'),
    cardNumber: Yup.string().required('Campo obrigatório'),
    cvv: Yup.string().required('Campo obrigatório'),
    cardCpf: Yup.string()
        .test('validate-cpf-cpnj', 'CPF inválido', value => {
            if (value && cpf.isValid(value)) return true;
            return false;
        })
        .required('Campo obrigatório'),
});

export const buyTicketsWithPixValidator = Yup.object().shape({
    buyerName: Yup.string().min(4, 'Digite um nome completo').required('Campo obrigatório'),
    buyerEmail: Yup.string().required('Campo obrigatório'),
    buyerTelephone: Yup.string().required('Campo obrigatório'),
    buyerCpf: Yup.string()
        .test('validate-cpf-cpnj', 'CPF inválido', value => {
            if (value && cpf.isValid(value)) return true;
            return false;
        })
        .required('Campo obrigatório'),
});
