import styled from 'styled-components';
import { FaShoppingCart } from 'react-icons/fa';
import { DefaultPage } from '../../_NewDefaultPage';

interface IPropsButtonSetPage {
    selected: boolean;
}

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(156, 246, 171, 1) 92%, rgba(53, 236, 84, 1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
`;

export const ContainerBanner = styled.div`
    width: 100%;
    height: 20rem;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;

    img {
        background-size: cover;
        max-height: 20rem;
        max-width: 100%;
    }
`;

export const Button = styled.button`
    border-radius: 5px;
    padding: 0.7rem 1rem;
    border: none;
    font-size: 1.2rem;
    background-color: ${props => props.theme.backgroudColor};
    color: ${props => props.theme.color};
`;

export const ContainerButtonNewCampaingn = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    @media (max-width: 450px) {
        width: 90%;
    }
`;

export const Title = styled.h1`
    width: 100%;
    text-align: center;
    margin: 2rem 0;

    @media (max-width: 800px) {
        font-size: 2.3rem;
    }
`;

export const Line = styled.hr`
    border-color: var(--graphite);
`;

export const ContainerCards = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
`;

export const ContainerCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 85rem;
    border: solid 2px var(--graphite);
    padding: 0.7rem;
    padding-bottom: 0;
    margin-bottom: 1rem;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    background-color: var(--graphite);
    padding-bottom: 1rem;

    @media (max-width: 1400px) {
        width: 70rem;
    }
    @media (max-width: 1150px) {
        width: 60rem;
    }
    @media (max-width: 940px) {
        width: 50rem;
    }
    @media (max-width: 790px) {
        width: 40rem;
    }
    @media (max-width: 600px) {
        width: 30rem;
    }
    @media (max-width: 450px) {
        width: 20rem;
    }
`;

export const ContentCard = styled.div`
    display: flex;
    width: 100%;
    height: auto;

    @media (max-width: 1150px) {
        flex-direction: column-reverse;
        height: auto;
    }

    @media (max-width: 620px) {
        flex-direction: column;
        height: auto;
    }
`;

export const ImageCard = styled.div`
    height: 100%;
    width: 50%;
    border-radius: 5px;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    margin-left: 1rem;

    @media (max-width: 1150px) {
        margin: 1rem auto;
    }

    @media (max-width: 620px) {
        height: auto;
        width: 100%;
    }
`;

export const ContainerInformations = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 1rem;
    align-items: center;

    @media (max-width: 1150px) {
        width: 100%;
    }

    @media (max-width: 620px) {
        width: 100%;
        padding-left: 0;
    }

    span {
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--white);
    }
`;

export const ContainerTicketInfo = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;

    @media (max-width: 450px) {
        align-items: center;
        margin-bottom: 1rem;
        width: 100%;
    }
`;

export const ContainerPrizes = styled(ContainerTicketInfo)`
    h1 {
        color: var(--white);
    }
`;

export const ContainerTitleCard = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    margin-bottom: 1rem;
`;

export const ContainerStatus = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 450px) {
        flex-wrap: wrap;
    }

    span.status {
        font-size: 1.8rem;
        font-weight: bold;
        margin-right: 15px;
    }
`;

export const Status = styled.span`
    font-size: 1.2rem !important;
    color: ${props => props.theme.color}!important;
    margin-right: 15px;
`;

export const CardTitle = styled.h2`
    font-size: 2.2rem;
    margin-top: 1rem;
    color: var(--white);
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 2.5rem;
    background-color: var(--dark-gray);
    border-radius: 5px;
    border: dashed 2px var(--primary);

    @media (max-width: 1150px) {
        margin-bottom: 1rem;
    }
`;

export const ProgressBarValue = styled.div`
    height: calc(100% + 5px);
    margin-left: -3px;
    margin-top: -3px;
    background-color: var(--primary);
    border-radius: 5px;
    width: ${props => props.theme.value};
    transition: 1s ease;
    transition-delay: 0.5s;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.5rem;
    max-width: 100%;

    :hover {
        span {
            opacity: 1;
            transition: all 200ms;
            z-index: 2;
        }
    }

    span {
        color: black;
        font-size: 1rem;
        opacity: 0;
        cursor: default;
    }
`;

export const ContainerCardButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;

    @media (max-width: 620px) {
        flex-wrap: wrap;
    }

    button {
        margin-left: 1rem;
        margin-bottom: 1rem;
    }
`;

export const PrizeDrawImage = styled.img`
    height: 100%;
`;

export const PrizeDrawName = styled.h3`
    color: var(--white);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
`;

export const ContainerSetPages = styled.div`
    margin: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const ButtonSetPage = styled.button<IPropsButtonSetPage>`
    background-color: transparent;
    border: none;
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    font-size: ${props => (props.selected ? '2rem' : '1.5rem')};
    margin: 0 15px;
`;

export const BottomBar = styled.div`
    width: 100%;
    height: 2px;
    background-color: var(--black);
    margin: 2rem 0;

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const ContainerInfos = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 2rem auto;

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
    }

    div.texts {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        h1 {
            margin-bottom: 1rem;
        }

        ul li {
            font-size: 1.3rem;
        }
    }
`;

export const TicketIcon = styled(FaShoppingCart)`
    height: 8rem;
    width: 8rem;

    @media (max-width: 650px) {
        margin-bottom: 2rem;
    }
`;

export const NumberOfPrizeDraw = styled.span`
    font-size: 1.5rem !important;
    font-weight: bold;
`;

export const ContainerInfoPrize = styled.div`
    width: 100%;
    display: flex;
    margin: 2rem 0;
    flex: 1;

    @media (max-width: 450px) {
        flex-direction: column;
    }
`;
