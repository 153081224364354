import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Loading } from 'styles/global';
import { App } from './App';

ReactDOM.render(
    // <React.StrictMode> // Problema com a lib de mask
    <Suspense fallback={<Loading>Carregando...</Loading>}>
        <App />
    </Suspense>,
    // </React.StrictMode>,
    document.getElementById('root')
);
