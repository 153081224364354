import React, { ButtonHTMLAttributes } from 'react';
import { IoIosSend } from 'react-icons/io';
import { TiArrowBack } from 'react-icons/ti';
import { CgCheckO } from 'react-icons/cg';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    // color?: 'red' | 'green' | 'purple' | 'yellow' | 'primary';
    icon?: 'send' | 'back' | 'check';
}

export function Button({
    loading = false,
    children,
    // color,
    icon,
    ...rest
}: ButtonProps): JSX.Element {
    return (
        <Container disabled={loading} {...rest}>
            {icon === 'back' && <TiArrowBack size={30} />}
            {icon === 'check' && <CgCheckO size={30} />}
            <span>{loading ? 'Carregando...' : children}</span>
            {icon === 'send' && <IoIosSend size={30} />}
        </Container>
    );
}
