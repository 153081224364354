import { useState } from 'react';
import { useAuth } from 'hooks/auth';
import { useCampaign } from 'hooks/campaign';
import { useHistory, Link } from 'react-router-dom';

import logo from 'assets/images/img/logo_verde.png';

import {
    Button,
    Container,
    ContainerButtonsClient,
    ContainerButtonsOrganizer,
    HamburguerMenuButtonOrganizer,
    HamburguerMenuButtonClient,
    LogoImage,
    NavButton,
    OverlayHiddenMenuOrganizer,
    WelcomeText,
    Content,
    ContainerButtonsAdmin,
    OverlayHiddenMenuClient,
    ContainerImageName,
    HamburguerMenuButtonAdmin,
} from './styles';

export function Header(): JSX.Element {
    const [showHiddenMenuOrganizer, setShowHiddenMenuOrganizer] = useState(false);
    const [showHiddenMenuClient, setShowHiddenMenuClient] = useState(false);
    const { signOut, user } = useAuth();
    const history = useHistory();
    const userType = user ? user.role : undefined;
    const { campaignPage } = useCampaign();

    function handleSignOut() {
        signOut();
        history.push('login');
    }

    return (
        <Container>
            {userType === 'organizer' && (
                <>
                    <ContainerImageName>
                        <Link className="home" to={campaignPage}>
                            <LogoImage src={logo} alt="iRifei" />
                        </Link>
                        <WelcomeText>{user.name}</WelcomeText>
                    </ContainerImageName>
                    <ContainerButtonsOrganizer>
                        <HamburguerMenuButtonOrganizer
                            onClick={() => setShowHiddenMenuOrganizer(!showHiddenMenuOrganizer)}
                        />
                    </ContainerButtonsOrganizer>
                    <OverlayHiddenMenuOrganizer theme={{ display: showHiddenMenuOrganizer ? 'flex' : 'none' }}>
                        <Button onClick={() => history.push('meus-dados')}>Meus dados</Button>
                        <Button onClick={() => handleSignOut()}>Sair</Button>
                    </OverlayHiddenMenuOrganizer>
                </>
            )}
            {!user && (
                <>
                    <Content>
                        <Link className="home" to={campaignPage}>
                            <LogoImage src={logo} alt="iRifei" />
                        </Link>
                        <ContainerButtonsClient>
                            <NavButton>
                                <Link to="/quem-somos">Quem somos</Link>
                            </NavButton>
                            <NavButton>
                                <Link to="/consultar-bilhetes">Meus bilhetes</Link>
                            </NavButton>
                            <NavButton>
                                <Link to="/suporte">Suporte</Link>
                            </NavButton>
                        </ContainerButtonsClient>

                        <HamburguerMenuButtonClient onClick={() => setShowHiddenMenuClient(!showHiddenMenuClient)} />
                        <OverlayHiddenMenuClient theme={{ display: showHiddenMenuClient ? 'flex' : 'none' }}>
                            <NavButton>
                                <Link to="/quem-somos">Quem somos</Link>
                            </NavButton>
                            <NavButton>
                                <Link to="/consultar-bilhetes">Meus bilhetes</Link>
                            </NavButton>
                            <NavButton>
                                <Link to="/suporte">Suporte</Link>
                            </NavButton>
                        </OverlayHiddenMenuClient>
                    </Content>
                </>
            )}
            {userType === 'admin' && (
                <>
                    <WelcomeText>Bem vindo, {user.name}</WelcomeText>
                    <ContainerButtonsAdmin>
                        <NavButton className="button-admin" onClick={() => history.push('organizadores')}>
                            Organizadores
                        </NavButton>
                        <NavButton className="button-admin" onClick={() => history.push('campanhas')}>
                            Campanhas
                        </NavButton>
                        <Button onClick={() => handleSignOut()}>Sair</Button>
                        <HamburguerMenuButtonAdmin
                            onClick={() => setShowHiddenMenuOrganizer(!showHiddenMenuOrganizer)}
                        />
                    </ContainerButtonsAdmin>
                    <OverlayHiddenMenuOrganizer theme={{ display: showHiddenMenuOrganizer ? 'flex' : 'none' }}>
                        <Button onClick={() => history.push('organizadores')}>Organizadores</Button>
                        <Button onClick={() => history.push('campanhas')}>Campanhas</Button>
                        <Button onClick={() => handleSignOut()}>Sair</Button>
                    </OverlayHiddenMenuOrganizer>
                </>
            )}
        </Container>
    );
}
