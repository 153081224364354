import { BottomBar, Container, ContainerInfos, Content, TicketIcon } from './styles';

export function WhoWeAre(): JSX.Element {
    return (
        <Container>
            <ContainerInfos>
                <TicketIcon />
                <div className="texts">
                    <h1>Quem somos</h1>
                    <ul>
                        <li>Plataforma de colaboração financeira entre amigos.</li>
                    </ul>
                    <BottomBar />
                </div>
            </ContainerInfos>
            <Content>
                <span>
                    O iRifei é inspirada na cultura tradicional brasileira da rifa e a adaptamos ao conceito moderno do
                    crowdfunding. O que diferencia o iRifei das demais rifas é o seu objetivo bem definido: usar a
                    tecnologia para obter doações para campanhas beneficentes ou sem fins lucrativos. Assim, apesar de
                    utilizarmos o modelo da rifa, que contempla brindes, a principal motivação de nossos usuários é a
                    doação para campanhas que o sensibilizem. O brinde, que deve possuir valor simbólico, é apenas a
                    cereja no bolo, diferenciando nosso modelo de crowdfunding.
                    <br />
                    <br />
                </span>
                <span>
                    Como nosso foco é ação entre amigos, recomendamos aos usuários que participem apenas em campanhas em
                    que haja uma relação mínima de confiança com o arrecadador. O iRifei oferece a plataforma como
                    serviço, mas a criação e gestão das campanhas são responsabilidade dos organizadores.
                    <br />
                    <br />
                </span>

                <h3>O que não somos</h3>

                <span>
                    <br />
                    O iRifei não é uma plataforma de jogos. Todo e qualquer pagamento efetuado pelo usuário doador ao
                    usuário arrecadador durante uma campanha de arrecadação é uma doação voluntária, não caracterizando
                    relação comercial ou de jogos de azar. Portanto, no momento da doação o usuário doador não deve
                    criar nenhuma expectativa de retorno financeiro na forma de produtos ou serviços.
                    <br />
                    <br />
                </span>

                <span>
                    O iRifei também não é um serviço comparável aos serviços financeiros oferecidos por instituições
                    bancárias ou administradoras de cartão de crédito, consistindo apenas em uma forma de facilitação de
                    arrecadação de fundos para causas beneficentes ou sem fins lucrativos, mediante serviços de gestão
                    de pagamento, por conta e ordem do usuário arrecadador.
                    <br />
                    <br />
                </span>
            </Content>
        </Container>
    );
}
