import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useCallback, useState } from 'react';
import api from 'services/api';
import { suportValidator } from 'validators/suport';
import { useToast } from 'hooks/toast';
import getValidationErrors from 'utils/getValidationErrors';
import * as Yup from 'yup';
import { BottomBar, Container, ContainerButton, ContainerInfos, Content, Form, TextArea, TicketIcon } from './styles';

export function Suport(): JSX.Element {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [nameCampaign, setNameCampaign] = useState('');
    const [message, setMessage] = useState('');
    const { addError, addSuccess } = useToast();
    const [errors, setErrors] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleSendEmail = useCallback(async () => {
        setIsButtonDisabled(true);
        try {
            setErrors({});

            await suportValidator.validate({ name, email, phone, nameCampaign }, { abortEarly: false });

            if (!nameCampaign.length) {
                addError('O nome da campanha não pode ser vazio');
                setIsButtonDisabled(false);
                return;
            }

            if (!message.length) {
                addError('A mensagem não pode ser vazia');
                setIsButtonDisabled(false);
                return;
            }

            await api.post('support/contact', {
                name,
                email,
                phone,
                nameCampaign,
                message,
            });

            addSuccess('Email enviado com sucesso');
            setEmail('');
            setName('');
            setPhone('');
            setNameCampaign('');
            setMessage('');

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
                addError('Campos inválidos');
            }
            addError('Erro ao enviar email');
        } finally {
            setIsButtonDisabled(false);
        }
    }, [name, email, phone, addError, addSuccess, nameCampaign, message]);

    return (
        <Container>
            <ContainerInfos>
                <TicketIcon />
                <div className="texts">
                    <h1>Suporte</h1>
                    <ul>
                        <li>
                            Preencha os campos abaixo caso tenha alguma dúvida sobre a plataforma ou qualquer
                            dificuldade de compra que retornaremos seu contato.
                        </li>
                    </ul>
                    <BottomBar />
                </div>
            </ContainerInfos>
            <Content>
                <Form>
                    <Input
                        name="name"
                        placeholder=""
                        value={name}
                        onChange={e => setName(e.target.value)}
                        label="Seu nome completo"
                        errors={errors}
                    />
                    <Input
                        name="phone"
                        placeholder=""
                        label="Celular (preferencialmente WhatsApp)"
                        type="masked"
                        mask="(99) 99999-9999"
                        value={phone}
                        errors={errors}
                        onChange={e => setPhone(e.target.value)}
                    />
                    <Input
                        name="email"
                        placeholder=""
                        label="Email"
                        value={email}
                        errors={errors}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Input
                        name="nameCampaign"
                        placeholder=""
                        label="Nome da campanha"
                        value={nameCampaign}
                        errors={errors}
                        onChange={e => setNameCampaign(e.target.value)}
                    />
                    <TextArea placeholder="Mensagem" value={message} onChange={e => setMessage(e.target.value)} />
                    <ContainerButton>
                        <Button
                            icon="send"
                            color="primary"
                            onClick={() => handleSendEmail()}
                            disabled={isButtonDisabled}
                        >
                            Enviar
                        </Button>
                    </ContainerButton>
                </Form>
            </Content>
        </Container>
    );
}
