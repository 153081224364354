import styled from 'styled-components';

export const Container = styled.button`
    background: var(--primary);
    min-width: 8rem;
    border-radius: 10px;
    padding: 0.5rem;
    border: none;
    font-size: 1.2rem;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);

    span {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 0 10px;
        color: #000;
    }
`;
