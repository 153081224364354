import { useAuth } from 'hooks/auth';
import { RouteProps as ReactDOMRouteProps, Route as ReactDOMRoute, Redirect } from 'react-router-dom';

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    component: React.ComponentType;
}

export const Route: React.FC<RouteProps> = ({ isPrivate = false, component: Component, ...rest }) => {
    const { user } = useAuth();

    return (
        <ReactDOMRoute
            {...rest}
            render={() => {
                if (!user) {
                    if (isPrivate) return <Redirect to={{ pathname: '/login' }} />;
                    return <Component />;
                }
                return <Component />;
            }}
        />
    );
};
