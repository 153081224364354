import styled from 'styled-components';
import { colors } from 'styles/colors';
import { DefaultPage } from '../../_DefaultPage';

interface IButtonProps {
    selected: boolean;
}

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h1`
    font-size: 3rem;
`;

export const Subtitle = styled.h2`
    font-size: 2rem;
    margin: 1rem 0;
`;

export const ContainerRescueData = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 2rem;

    @media (max-width: 1200px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    @media (max-width: 480px) {
        width: 95%;
    }
`;

export const ContainerBankInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: solid 2px var(--graphite);
    border-radius: 10px;
    padding: 0.7rem 1.5rem;
    padding-bottom: 0;
    margin-bottom: 1rem;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    background-color: var(--secondary);

    p.label {
        font-size: 1.3rem;
        color: var(--graphite);
        margin-bottom: 1rem;
        width: auto;
        text-align: left;
    }

    p {
        width: 30rem;
        text-align: center;
        font-size: 1.3rem;
        color: var(--graphite);
        margin-bottom: 1rem;

        @media (max-width: 650px) {
            width: 100%;
            padding: 0;
        }
    }

    div.agency {
        width: 15rem;
    }
    div.account {
        width: 20rem;
    }
`;

export const ContainerInput = styled.div`
    width: 100%;
    margin-bottom: 1rem;

    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const ContainerFinallyRescue = styled.div`
    display: flex;
    flex-direction: column;
    width: 40rem;

    @media (max-width: 650px) {
        width: 30rem;
    }

    @media (max-width: 480px) {
        width: 25rem;
    }

    span {
        font-size: 1.5rem;
        color: var(--graphite);
        margin-bottom: 1rem;
        font-weight: 400;
    }

    textarea {
        width: 35rem;
        min-height: 10rem;
        border-radius: 10px;
        background-color: var(--white);
        border: solid 2px var(--graphite);
        padding: 1rem;
        font-size: 1.3rem;
        color: var(--graphite);
        margin-top: 3rem;
        margin-bottom: 2rem;

        @media (max-width: 650px) {
            width: 25rem;
        }

        @media (max-width: 480px) {
            width: 100%auto;
        }
    }
`;

export const ContainerButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
        margin-right: 15px;
    }
`;

export const PaymentData = styled.div`
    display: flex;
    flex-direction: column;
    width: 40rem;
    margin-right: 1rem;

    @media (max-width: 650px) {
        width: 30rem;
        padding: 0.7rem 0.5rem;
    }

    @media (max-width: 480px) {
        width: 25rem;
    }
`;

export const ContainerPaymentButtons = styled.div`
    width: 40rem;
    display: flex;
    justify-content: center;

    @media (max-width: 650px) {
        width: 30rem;
    }

    @media (max-width: 480px) {
        width: 25rem;
    }
`;

export const ButtonPaymentMethod = styled.button<IButtonProps>`
    background-color: ${props => (props.selected ? colors.primary : colors.darkGray)};
    color: ${props => (props.selected ? colors.white : colors.graphite)};
    border: none;
    border-radius: 5px 5px 0 0;
    padding: 10px 15px;
    margin: 0 15px;
    min-width: 7rem;
    font-weight: bold;

    transition: all 400ms;
`;

export const Select = styled.select`
    width: 100%;
    background: var(--white);
    border: solid 2px black;
    height: 2.64rem;
    border-radius: 0.4rem;

    padding-left: 1.5rem;
    font-size: 1.2rem;
    color: var(--graphite);
    margin-top: 0.5rem;

    :focus {
        box-shadow: 0 2px 5px ${`${colors.graphite}99`};
    }
`;
