import { Button } from 'components/Button';
import { Container, Content, CloseButton, ContainerButtons, ContainerTitle } from './styles';

interface IProps {
    showOverlay: () => void;
    cancelPurchase: (id: string) => Promise<void>;
    purchaseId: string;
}

export function OverlayCancelPurchase({ showOverlay, purchaseId, cancelPurchase }: IProps): JSX.Element {
    return (
        <Container>
            <Content>
                <ContainerTitle>
                    <div className="text">
                        <h1>Tem certeza que deseja cancelar essa compra? </h1>
                        <br />
                        Após essa ação os números de bilhetes comprados ficarão disponíveis para compras futuras.
                        <br />
                    </div>
                    <CloseButton fill="#f00" size={30} onClick={() => showOverlay()} />
                </ContainerTitle>

                <ContainerButtons>
                    <Button color="red" onClick={() => showOverlay()}>
                        Não
                    </Button>
                    <Button color="green" onClick={() => cancelPurchase(purchaseId)}>
                        Sim
                    </Button>
                </ContainerButtons>
            </Content>
        </Container>
    );
}
