import styled from 'styled-components';
import { IoTicket } from 'react-icons/io5';
import { DefaultPage } from '../../_NewDefaultPage';

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @media (max-width: 450px) {
        padding: 2rem 10px;
    }
`;

export const Title = styled.h1`
    font-size: 2.5rem;
    margin: 2rem 0;

    @media (max-width: 450px) {
        font-size: 2rem;
    }
`;

export const ContainerTable = styled.div`
    height: auto;
    overflow-x: auto;
`;

export const Table = styled.table`
    border-collapse: collapse;
    thead tr td {
        background-color: var(--gray);
        color: var(--graphite);
        font-weight: bold;
        padding: 0.5rem 4rem;
        font-weight: 400;
        font-size: 1.2rem;
    }

    tr {
        color: var(--graphite);
        padding: 0.5rem 4rem;
        font-weight: 400;
        font-size: 1.3rem;
        background-color: var(--white);
    }

    td {
        text-align: center;
        border: solid 2px var(--graphite);
        margin: 0;
    }
`;

export const TicketError = styled.h2`
    width: 100%;
    text-align: center;
    margin-top: 4rem;

    a {
        color: #000;
    }
`;

export const BottomBar = styled.div`
    width: 85%;
    height: 2px;
    background-color: var(--black);
    margin: 2rem 0;

    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const ContainerInfos = styled.div`
  display: flex;
  margin: 2rem auto;

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }

  div.texts {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;

    h1 {
      margin-bottom: 1rem;
    }

    ul li {
      font-size: 1.3rem;
    }
  }

  div.campaigns {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  button {
    background: var(--primary);
    min-width: 8rem;
    border-radius: 10px;
    padding: 0.5rem;
    border: none;
    font-size: 1.2rem;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
  }

  button.selected {
    background: var(--darkGreen);
`;

export const TicketIcon = styled(IoTicket)`
    height: 8rem;
    width: 8rem;

    @media (max-width: 650px) {
        margin-bottom: 2rem;
    }
`;
