import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    div.content {
        display: flex;
        justify-content: space-between;
        width: 90%;

        @media (max-width: 1000px) {
            flex-direction: column;
        }
    }
`;

export const Title = styled.h1`
    margin: 2rem 0;
`;

export const ContainerTicket = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: auto;
    margin-bottom: 2rem;

    @media (max-width: 1150px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Prizes = styled.div`
    width: 30%;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    overflow-y: auto;
    border: solid 3px var(--dark-gray);
    border-radius: 15px;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    background-color: var(--secondary);

    @media (max-width: 1000px) {
        margin-bottom: 2rem;
        width: 100%;
    }

    h1 {
        font-size: 2rem;
        font-weight: 400;
        color: var(--black);
        margin-left: 0.5rem;
        margin-bottom: 1rem;
    }

    span {
        color: var(--black);
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
`;

export const Ticket = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    border: solid 3px var(--dark-gray);
    border-radius: 10px;
    padding: 0.7rem;
    padding-bottom: 0;
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    background-color: var(--secondary);

    @media (max-width: 1000px) {
        margin-bottom: 2rem;
        width: 100%;
    }
`;

export const ContentTicket = styled.div`
    display: flex;
    width: 100%;
    height: 27rem;
    margin-bottom: 0.7rem;

    @media (max-width: 700px) {
        flex-direction: column;
        height: auto;
    }
`;

export const ImageTicket = styled.div`
    height: 25rem;
    width: 25rem;
    border-radius: 5px;
    background-color: var(--dark-gray);
    overflow: hidden;
    background-size: cover;
    object-fit: cover;

    @media (max-width: 700px) {
        height: auto;
        width: 100%;
        overflow: hidden;
    }
`;

export const ContainerInformations = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 25rem;
    padding-left: 1rem;
    justify-content: space-between;

    div.texts {
        display: flex;
        flex-direction: column;
    }

    div.infos {
        display: flex;
        flex-direction: column;
    }

    div.numbers-of-sold {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 700px) {
        width: 100%;
        padding-left: 0;
    }

    span {
        font-size: 1.2rem;
        line-height: 2rem;
    }
`;

export const ContainerTitleTicket = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    margin-bottom: 1rem;
`;

export const Status = styled.span`
    font-size: 1.5rem !important;
    font-weight: bold;
    color: ${props => props.theme.color};
`;

export const TicketTitle = styled.h2`
    font-size: 1.6rem;
    margin-top: 1rem;
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 1.7rem;
    background: var(--dark-gray);
    border-radius: 5px;
`;

export const ProgressBarValue = styled.div`
    height: 100%;
    background-color: #fa9c17;
    border-radius: 5px;
    width: ${props => props.theme.value};
    transition: 1s ease;
    transition-delay: 0.5s;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.5rem;

    :hover {
        span {
            opacity: 1;
            transition: all 200ms;
            z-index: 2;
        }
    }

    span {
        color: black;
        font-size: 1rem;
        opacity: 0;
        cursor: default;
    }
`;

export const PrizeDrawImage = styled.img`
    height: 100%;
    background-size: cover;
    max-height: 25rem;

    @media (max-width: 700px) {
        max-height: auto;
    }
`;

export const PrizeDrawName = styled.h3`
    color: var(--white);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
`;

export const ContainerWinners = styled.div`
    width: 90%;
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    padding: 1rem 2rem;
    border-radius: 10px;
    border: solid 2px var(--dark-gray);
    box-shadow: 0px 6px 8px 3px rgba(0, 0, 0, 0.6);
    background-color: var(--secondary);

    @media (max-width: 440px) {
        padding: 1rem 0.3rem;
    }

    h1 {
        margin-bottom: 1rem;
        width: 100%;
        text-align: center;
    }

    div.container-awards {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        div.award {
            display: flex;
            flex-direction: column;
            width: 20rem;
            border: solid 1px var(--graphite);
            padding: 1rem;
            border-radius: 15px;
            margin: 0 1rem;
            margin-bottom: 2rem;
            box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.6);
            background-color: var(--light-gray);

            h2 {
                margin-bottom: 1rem;
            }

            div.field {
                display: flex;
            }

            span {
                font-weight: bold;
                font-size: 2.3rem;
                margin-bottom: 1rem;
                font-weight: normal;
                width: 100%;
                text-align: center;
            }
        }
    }
`;
