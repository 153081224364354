import { AppProvider } from 'hooks';
import { Routes } from './routes';
import { GlobalStyles } from './styles/global';
import 'bootstrap/dist/css/bootstrap.min.css';

export function App(): JSX.Element {
    return (
        <>
            <AppProvider>
                <Routes />
            </AppProvider>
            <GlobalStyles />
        </>
    );
}
