import React, { useCallback, useEffect, useState, Suspense } from 'react';
import { Loading } from 'styles/global';
import { useLocation } from 'react-router-dom';
import { useCampaign } from 'hooks/campaign';
import api from 'services/api';

import { Container, ContainerStepsProgress, Content, ProgressBar, ProgressBarValue } from './styles';

interface IProps {
    isEdit: boolean;
    id: string;
    isAdmin: boolean;
}

const RegisterCampaignData = React.lazy(() => import('components/RegisterCampaignData'));
const RegisterPrizeImages = React.lazy(() => import('components/RegisterPrizeImages'));
const RegisterCampaignAssociate = React.lazy(() => import('components/RegisterCampaignAssociate'));

export function Campaign(): JSX.Element {
    const { state } = useLocation<IProps>();
    const [currentStep, setCurrentStep] = useState('1');
    const { setIdOfCampaign, campaignIsNotFinished, finishCampaign } = useCampaign();
    const [registerStep, setRegisterStep] = useState<'BASIC_DATA' | 'IMAGES_DATA' | 'ASSOCIATES_DATA'>('BASIC_DATA');

    function handleChangeRegisterStep(step: 'BASIC_DATA' | 'IMAGES_DATA' | 'ASSOCIATES_DATA') {
        setRegisterStep(step);
    }

    function handleGetStepsProgress() {
        if (registerStep === 'BASIC_DATA') return '33%';
        if (registerStep === 'IMAGES_DATA') return '66%';
        return '90%';
    }

    const handleGetCurrentStep = useCallback(() => {
        if (registerStep === 'BASIC_DATA') setCurrentStep('1');
        else if (registerStep === 'IMAGES_DATA') setCurrentStep('2');
        else setCurrentStep('3');
    }, [registerStep]);

    useEffect(() => {
        handleGetCurrentStep();
    }, [registerStep, handleGetCurrentStep]);

    useEffect(() => {
        async function getStatusOfCampaign() {
            try {
                const response = await api.get(`campaigns/${state.id}`);
                if (response.data.data.status === 'FINISHED') finishCampaign();
                else campaignIsNotFinished();
            } catch (error) {
                campaignIsNotFinished();
            }
        }
        setIdOfCampaign(state.id);
        if (!state.id) campaignIsNotFinished();
        else getStatusOfCampaign();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.id]);

    return (
        <Container>
            <Content>
                <ContainerStepsProgress>
                    <h1>Etapas {state?.isEdit ? 'da edição' : 'do cadastro'}</h1>
                    <span>{currentStep} de 3</span>
                    <ProgressBar>
                        <ProgressBarValue
                            theme={{
                                width: handleGetStepsProgress(),
                            }}
                        />
                    </ProgressBar>
                </ContainerStepsProgress>
                {registerStep === 'BASIC_DATA' && (
                    <Suspense fallback={<Loading>Carregando...</Loading>}>
                        <RegisterCampaignData
                            isEdit={state?.isEdit || false}
                            id={state?.id}
                            changeRegisterStep={handleChangeRegisterStep}
                            isAdmin={state.isAdmin || false}
                        />
                    </Suspense>
                )}
                {registerStep === 'IMAGES_DATA' && (
                    <Suspense fallback={<Loading>Carregando...</Loading>}>
                        <RegisterPrizeImages
                            isAdmin={state.isAdmin || false}
                            changeRegisterStep={handleChangeRegisterStep}
                            isEdit={state?.isEdit}
                        />
                    </Suspense>
                )}
                {registerStep === 'ASSOCIATES_DATA' && (
                    <Suspense fallback={<Loading>Carregando...</Loading>}>
                        <RegisterCampaignAssociate
                            changeRegisterStep={handleChangeRegisterStep}
                            isAdmin={state.isAdmin || false}
                            isEdit={state?.isEdit}
                        />
                    </Suspense>
                )}
            </Content>
        </Container>
    );
}
