import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;

    h1 {
        margin-bottom: 2rem;
    }
`;

export const ContentInput = styled.div`
    background: var(--secondary);
    border-radius: 25px;
    padding: 4rem;
    width: 40rem;
    height: auto;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
    justify-content: space-between;

    @media (max-width: 650px) {
        width: 30rem;
        padding: 4rem 2rem;
    }

    @media (max-width: 450px) {
        width: 20rem;
        padding: 4rem 1rem;
        height: auto;
    }

    div.container-button {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
    }
`;
