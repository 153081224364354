import styled from 'styled-components';

import { AiFillCloseCircle } from 'react-icons/ai';

export const Container = styled.div`
    width: 100vw;
    height: 100%;
    z-index: 10;
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    width: 45rem;
    height: 15rem;
    background-color: var(--secondary);
    box-shadow: 0px 3px 8px 3px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: space-between;

    div.text {
        width: 90%;
    }

    h1 {
        font-size: 1.7rem;
        width: 100%;
    }
    h2 {
        font-size: 1.5rem;
        width: 100%;
    }

    @media (max-width: 950px) {
        width: 50rem;
    }

    @media (max-width: 780px) {
        width: 48rem;
    }

    @media (max-width: 600px) {
        width: 30rem;
    }

    @media (max-width: 440px) {
        height: 20rem;
        width: 25rem;
    }

    @media (max-width: 365px) {
        width: 95%;
        padding: 1.5rem 0.5rem;
    }

    @media (max-height: 880px) {
    }

    @media (max-height: 730px) {
        height: 45vh;
    }
`;

export const ContainerTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

export const ContainerButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;
export const CloseButton = styled(AiFillCloseCircle)`
    cursor: pointer;
`;
