import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { colors } from 'styles/colors';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import { Carousel } from 'react-bootstrap';
import defaultImage from 'assets/images/img/defaultPrizeDrawImage.png';
import { formatPrizeDrawDate } from 'utils/formatDate';
import moment from 'moment-timezone';
import { OverlayRequestPublishCampaign } from 'components/OverlayRequestPublishCampaign';
import { useCampaign } from 'hooks/campaign';
import { Button } from 'components/Button';
import { IoTrophy } from 'react-icons/io5';

import { ValidateImageFormat } from 'utils/validateImageFormat';
import {
    Card,
    CardTitle,
    Container,
    ContainerButtonNewCampaingn,
    ContainerCardButtons,
    ContainerCards,
    ContainerInformations,
    ContainerStatus,
    ContainerTitleCard,
    Content,
    ContentCard,
    ImageCard,
    Line,
    PrizeDrawImage,
    PrizeDrawName,
    ProgressBar,
    ProgressBarValue,
    Status,
    ContainerBanner,
    ContainerSetPages,
    ButtonSetPage,
    ContainerInfos,
    TicketIcon,
    BottomBar,
    ContainerTicketInfo,
    ContainerPrizes,
    NumberOfPrizeDraw,
    ContainerInfoPrize,
    ContainerCard,
    Title,
} from './styles';

interface IBanners {
    id: number;
    image: string;
}

interface IPrizePhotos {
    id: string;
    name: string;
    url: string;
}
export interface ICampaignsData {
    id: string;
    maxAmountOfTickets: number;
    amountOfTickets: number;
    affiliateAmountOfTickets: number;
    active: boolean;
    amountOfTicketsSold: number;
    organizerName: string;
    name: string;
    affiliateName: string;
    prizeDrawDate: string;
    ticketPrice: number;
    prize1: null | string;
    prize2: null | string;
    prize3: null | string;
    prize4: null | string;
    prize5: null | string;
    ticketNumberWinner1: null | string;
    ticketNumberWinner2: null | string;
    ticketNumberWinner3: null | string;
    ticketNumberWinner4: null | string;
    ticketNumberWinner5: null | string;
    prizePhotos: Array<IPrizePhotos>;
    regulation: string | null;
    status: string;
}

export function HomePage(): JSX.Element {
    const [campaigns, setCampaigns] = useState<Array<ICampaignsData>>([] as Array<ICampaignsData>);
    const [banners, setBanners] = useState<Array<IBanners>>([] as Array<IBanners>);
    const [showOverlay, setShowOverlay] = useState(false);
    const history = useHistory();
    const { addError, addSuccess } = useToast();
    const [idToPublish, setIdToPublish] = useState('');
    const { setIdOfCampaign } = useCampaign();
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [pageSelected, setPageSelected] = useState(1);
    const [arrayOfPages, setArrayOfPages] = useState<number[]>([]);

    function handleSetNumberOfPages(count: number) {
        setNumberOfPages(Math.floor(count / 10) + 1);
    }

    function handleShowPages() {
        const arrayAux: number[] = [];
        for (let i = 0; i < numberOfPages; i += 1) {
            arrayAux.push(i + 1);
        }
        setArrayOfPages(arrayAux);
    }

    useEffect(() => {
        handleShowPages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfPages]);

    async function handleGetCampaigns() {
        try {
            const response = await api.get(`campaigns/?page=${pageSelected}`);
            handleSetNumberOfPages(response.data.count);
            if (response.data.data) {
                setCampaigns(response.data.data);
            }
        } catch (error) {
            addError('Erro ao carregar campanhas');
        }
    }

    useEffect(() => {
        setBanners([]);
        handleGetCampaigns();
        setIdOfCampaign('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleGetCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelected]);

    function handleShowOverlay() {
        setShowOverlay(!showOverlay);
    }

    async function handleRequestActiveCampaign(id: string) {
        try {
            await api.post(`campaigns/request-publish/${id}`);
            addSuccess('Solicitação enviada.');
            handleGetCampaigns();
            setShowOverlay(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            addError('Erro ao solicitar publicação da campanha');
            if (error.response?.data?.error?.message) addError(error.response.data.error.message);
        }
    }

    function handleFormatDate(date: string) {
        // recebe AAAA-MM-DD e devolve DD/MM/AAAA
        const newDate = date.replaceAll('-', '/');
        const separateDate = newDate.split('/');
        const formatedDate = [];
        formatedDate.push(separateDate[2]);
        formatedDate.push(separateDate[1]);
        formatedDate.push(separateDate[0]);
        return `${formatedDate[0]}/${formatedDate[1]}/${formatedDate[2]}`;
    }

    function handleCalculateDifDate(prizeDrawDate: string) {
        let hours = '';
        let date = '';
        if (prizeDrawDate.length) {
            // Transforma a data do sorteio em DD/MM/AAAA HH:MM:SS
            hours = `${prizeDrawDate.substring(11, 16)}:00`;
            date = prizeDrawDate.substring(0, 10);
        }
        let dateOfPrizeDraw = `${handleFormatDate(date)} ${hours}`;

        const currentDate = new Date().toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
        });
        const currentFullDate = currentDate.substring(0, 10);
        dateOfPrizeDraw = dateOfPrizeDraw.substring(0, 10);

        // Calculando diferença de tempo entre a data do sorteio e a data atual
        const diff = moment(dateOfPrizeDraw, 'DD/MM/YYYY').diff(moment(currentFullDate, 'DD/MM/YYYY'));

        // calculando diferença em dias e horas
        const days = Math.floor(moment.duration(diff).asDays());
        if (days === 1) return 'Hoje é o último dia para comprar seu nº da sorte!';
        if (days === 0) return 'Hoje é o dia do sorteio!';
        if (days < 0) return 'Campanha finalizada!';

        return `${days} dias `;
    }

    function handleGetTicketSoldPercentage(total: number | undefined, sold: number | undefined) {
        if (total && sold) return `${Math.round((sold / total) * 100)}%`;
        return '0%';
    }

    return (
        <Container>
            <Content>
                {banners.length ? (
                    <ContainerBanner>
                        <Carousel>
                            {banners.map(image => {
                                return (
                                    <Carousel.Item key={image.id}>
                                        <img className="d-block w-100" src={image.image} alt="" />
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </ContainerBanner>
                ) : (
                    <></>
                )}

                <ContainerInfos>
                    <TicketIcon />
                    <div className="texts">
                        <h1>Espaço do vendedor</h1>
                        <ul>
                            <li>Utilize esse espaço para cadastrar novas rifas.</li>
                            <li>Você também pode gerenciar rifas que publicou.</li>
                        </ul>
                        <BottomBar />
                    </div>
                </ContainerInfos>

                <ContainerButtonNewCampaingn>
                    <Button
                        icon="check"
                        onClick={() =>
                            history.push('editar-campanha', {
                                isEdit: false,
                            })
                        }
                    >
                        Clique aqui para cadastrar uma nova campanha
                    </Button>
                </ContainerButtonNewCampaingn>
                <Line />

                <Title>Campanhas</Title>

                <ContainerCards>
                    {campaigns.map(obj => {
                        return (
                            <ContainerCard key={obj.id}>
                                <Card>
                                    <ContentCard>
                                        <ContainerInformations>
                                            <ContainerTitleCard>
                                                <ContainerStatus>
                                                    <span className="status">Status: </span>
                                                    <Status
                                                        theme={{
                                                            color: obj.active ? '#0cc033' : colors.red,
                                                        }}
                                                    >
                                                        {obj.active ? 'Ativa' : 'Inativa'}
                                                    </Status>
                                                    {(obj.status === 'CREATED' || obj.status === 'REQUESTED') && (
                                                        <Status theme={{ color: colors.purple }}>Não publicado</Status>
                                                    )}
                                                    {obj.status === 'PUBLISHED' && (
                                                        <Status theme={{ color: '#0cc033' }}>Publicada</Status>
                                                    )}
                                                    {obj.status === 'FINISHED' && (
                                                        <Status theme={{ color: colors.red }}>Finalizada</Status>
                                                    )}
                                                </ContainerStatus>
                                            </ContainerTitleCard>
                                            <ContainerInfoPrize>
                                                <ContainerTicketInfo>
                                                    <CardTitle>{obj.name}</CardTitle>
                                                    <span>
                                                        Valor unitário:{' '}
                                                        <NumberOfPrizeDraw>R${obj.ticketPrice}</NumberOfPrizeDraw>{' '}
                                                    </span>
                                                    <span>
                                                        Tempo restante:{' '}
                                                        <NumberOfPrizeDraw>
                                                            {obj.status === 'FINISHED'
                                                                ? 'Campanha finalizada!'
                                                                : handleCalculateDifDate(obj.prizeDrawDate)}
                                                        </NumberOfPrizeDraw>
                                                    </span>
                                                    <span>
                                                        Data do sorteio: {'  '}
                                                        <NumberOfPrizeDraw>
                                                            {formatPrizeDrawDate(obj.prizeDrawDate)}
                                                        </NumberOfPrizeDraw>
                                                    </span>
                                                </ContainerTicketInfo>
                                                <ContainerPrizes>
                                                    <h1>
                                                        Prêmios <IoTrophy fill="#fff" />
                                                    </h1>

                                                    {obj?.prize1 && (
                                                        <span>
                                                            <NumberOfPrizeDraw>1º</NumberOfPrizeDraw> Prêmio:{' '}
                                                            {obj.prize1}
                                                        </span>
                                                    )}
                                                    {obj?.prize2 && (
                                                        <span>
                                                            <NumberOfPrizeDraw>2º</NumberOfPrizeDraw> Prêmio:{' '}
                                                            {obj.prize2}
                                                        </span>
                                                    )}
                                                    {obj?.prize3 && (
                                                        <span>
                                                            <NumberOfPrizeDraw>3º</NumberOfPrizeDraw> Prêmio:{' '}
                                                            {obj.prize3}
                                                        </span>
                                                    )}
                                                    {obj?.prize4 && (
                                                        <span>
                                                            <NumberOfPrizeDraw>4º</NumberOfPrizeDraw> Prêmio:{' '}
                                                            {obj.prize4}
                                                        </span>
                                                    )}
                                                    {obj?.prize5 && (
                                                        <span>
                                                            <NumberOfPrizeDraw>5º</NumberOfPrizeDraw> Prêmio:{' '}
                                                            {obj.prize5}
                                                        </span>
                                                    )}
                                                </ContainerPrizes>
                                            </ContainerInfoPrize>
                                            <span>
                                                Total vendido até agora:{' '}
                                                <NumberOfPrizeDraw>
                                                    {obj.amountOfTicketsSold} / {obj.amountOfTickets}
                                                </NumberOfPrizeDraw>{' '}
                                            </span>
                                            <ProgressBar>
                                                <ProgressBarValue
                                                    theme={{
                                                        value: handleGetTicketSoldPercentage(
                                                            obj.maxAmountOfTickets,
                                                            obj.amountOfTicketsSold
                                                        ),
                                                    }}
                                                >
                                                    <span>
                                                        {handleGetTicketSoldPercentage(
                                                            obj.maxAmountOfTickets,
                                                            obj.amountOfTicketsSold
                                                        )}
                                                    </span>
                                                </ProgressBarValue>
                                            </ProgressBar>
                                        </ContainerInformations>
                                        <ImageCard>
                                            <Carousel>
                                                {obj.prizePhotos.map(image => {
                                                    return (
                                                        <Carousel.Item key={image.id}>
                                                            <PrizeDrawImage
                                                                className="d-block w-100"
                                                                src={
                                                                    ValidateImageFormat(image.url)
                                                                        ? image.url
                                                                        : defaultImage
                                                                }
                                                                alt={image.name}
                                                            />
                                                            <Carousel.Caption>
                                                                <PrizeDrawName className="prize-draw-name">
                                                                    {ValidateImageFormat(image.url)
                                                                        ? image.name
                                                                        : 'Prêmio'}
                                                                </PrizeDrawName>
                                                            </Carousel.Caption>
                                                        </Carousel.Item>
                                                    );
                                                })}
                                                {!obj.prizePhotos.length && (
                                                    <Carousel.Item>
                                                        <PrizeDrawImage
                                                            className="d-block w-100"
                                                            src={defaultImage}
                                                            alt="Não há fotos dos prêmios dessa campanha"
                                                        />
                                                    </Carousel.Item>
                                                )}
                                            </Carousel>
                                        </ImageCard>
                                    </ContentCard>
                                </Card>
                                <ContainerCardButtons>
                                    <Button
                                        onClick={() =>
                                            history.push('editar-campanha', {
                                                isEdit: true,
                                                id: obj.id,
                                            })
                                        }
                                        color="primary"
                                    >
                                        Visualizar campanha
                                    </Button>
                                    {!obj.active && (
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                setIdToPublish(obj.id);
                                                handleShowOverlay();
                                            }}
                                            disabled={obj.status === 'REQUESTED'}
                                        >
                                            Solicitar publicação da campanha
                                        </Button>
                                    )}

                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            if (obj.status !== 'FINISHED') {
                                                addError(
                                                    'Só é possível resgatar as arrecadações depois de finalizar a campanha'
                                                );
                                                return;
                                            }
                                            history.push('resgatar', { idCampaign: obj.id });
                                        }}
                                    >
                                        Resgatar vendas
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={() => history.push('compradores', { campaignId: obj.id })}
                                    >
                                        Relatório de compras
                                    </Button>
                                </ContainerCardButtons>
                            </ContainerCard>
                        );
                    })}
                    {showOverlay && (
                        <OverlayRequestPublishCampaign
                            campaignId={idToPublish}
                            showOverlay={handleShowOverlay}
                            requestPublishCampaign={handleRequestActiveCampaign}
                        />
                    )}
                </ContainerCards>
                <ContainerSetPages>
                    {arrayOfPages.map(page => {
                        return (
                            <ButtonSetPage selected={pageSelected === page} onClick={() => setPageSelected(page)}>
                                {page}
                            </ButtonSetPage>
                        );
                    })}
                </ContainerSetPages>
            </Content>
        </Container>
    );
}
