import * as Yup from 'yup';

export const resetPasswordValidator = Yup.object().shape({
    password: Yup.string()
        .required('Campo obrigatório')
        .min(8, 'Deve ter pelo menos oito caracteres')
        .matches(
            /^(?=\D*\d)(?=[^A-Za-z]*[A-Za-z])[\w#?!@$%^&*-]{8,48}$/,
            'A senha precisa ter pelo menos 8 caracteres, letras e números'
        ),
    confirmPassword: Yup.string()
        .required('Campo obrigatório')
        .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais'),
});
