import moment from 'moment';
import React from 'react';

type Props = {
    offsetMilliseconds: number;
};

export const Counter = (props: Props): JSX.Element => {
    const { offsetMilliseconds } = props;
    const [counter, setCounter] = React.useState(offsetMilliseconds);
    const date = new Date(counter);

    React.useEffect(() => {
        const startCounter = () => {
            if (counter === 0) {
                return null;
            }

            const timeout = setTimeout(() => {
                setCounter(old => old - 1000);
            }, 1000);

            return timeout;
        };

        const timeout = startCounter();

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [counter]);

    return (
        <span
            style={{
                fontSize: '14px',
                display: 'block',
                margin: 'auto',
                textAlign: 'center',
            }}
        >
            {counter > 0 ? (
                <>Você tem {moment(date).format('mm [minutos e ] ss [segundos]')} para efetuar o pagamento.</>
            ) : (
                <span style={{ color: 'red' }}>PIX expirado.</span>
            )}
        </span>
    );
};
