import * as Yup from 'yup';

export const campaignFeesValidator = Yup.object().shape({
    platformFee: Yup.string().required('Campo obrigatório'),
    paternFee: Yup.string().required('Campo obrigatório'),
    irifeiFee: Yup.string().required('Campo obrigatório'),
    paternExpense: Yup.string().required('Campo obrigatório'),
    elo: Yup.string().required('Campo obrigatório'),
    visa: Yup.string().required('Campo obrigatório'),
    mastercard: Yup.string().required('Campo obrigatório'),
    type: Yup.string().required('Campo obrigatório'),
});
