import { useCallback, useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useHistory } from 'react-router-dom';
import { consultTicketsEmailValidator, consultTicketsValidator } from 'validators/consultTickets';
import { useToast } from 'hooks/toast';
import getValidationErrors from 'utils/getValidationErrors';
import { useQuery } from 'hooks/getParams';
import * as Yup from 'yup';

import { Container, ContainerButton, ContainerInput, Content, Title, RadioContainer } from './styles';

export function ConsultTickets(): JSX.Element {
    const [documentType, setDocumentType] = useState('cpf');
    const [document, setDocument] = useState('');
    const [email, setEmail] = useState('');
    const history = useHistory();
    const { addError } = useToast();
    const [errors, setErrors] = useState({});
    const query = useQuery();

    useEffect(() => {
        const cpf = query.get('cpf');
        if (cpf) setDocument(cpf);
    }, [query]);

    const handleSearchCpf = useCallback(async () => {
        try {
            setErrors({});
            await consultTicketsValidator.validate({ document }, { abortEarly: false });

            history.push('meus-bilhetes', { cpf: document });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
            }

            addError('O CPF informado não é válido');
        }
    }, [document, addError, history]);

    const handleSearchEmail = useCallback(async () => {
        try {
            setErrors({});
            await consultTicketsEmailValidator.validate({ email }, { abortEarly: false });

            history.push('meus-bilhetes-associado', { email });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
            }
            addError('O email informado não é válido');
        }
    }, [email, addError, history]);

    const handleSearch = useCallback(() => {
        if (documentType === 'cpf') {
            handleSearchCpf();
        } else if (documentType === 'email') {
            handleSearchEmail();
        }
    }, [documentType, handleSearchCpf, handleSearchEmail]);

    return (
        <Container>
            <Content>
                <Title>Consultar meus bilhetes</Title>
                <RadioContainer>
                    <label>
                        <input
                            type="radio"
                            value="cpf"
                            checked={documentType === 'cpf'}
                            onChange={() => setDocumentType('cpf')}
                        />
                        Comprador
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="email"
                            checked={documentType === 'email'}
                            onChange={() => setDocumentType('email')}
                        />
                        Vendedor
                    </label>
                </RadioContainer>
                <ContainerInput>
                    {documentType === 'cpf' ? (
                        <Input
                            name="document"
                            type="masked"
                            mask="999.999.999-99"
                            placeholder="Informe seu CPF"
                            label="Informe seu CPF"
                            value={document}
                            errors={errors}
                            onChange={e => setDocument(e.target.value)}
                        />
                    ) : (
                        <Input
                            name="document"
                            type="email"
                            placeholder="Informe seu email"
                            label="Informe seu email"
                            value={email}
                            errors={errors}
                            onChange={e => setEmail(e.target.value)}
                        />
                    )}
                </ContainerInput>
                <ContainerButton>
                    <Button color="green" onClick={() => handleSearch()}>
                        Consultar
                    </Button>
                </ContainerButton>
            </Content>
        </Container>
    );
}
