import { Switch } from 'react-router-dom';
import { useAuth } from 'hooks/auth';

import { SignIn } from 'pages/Auth/SignIn';
import { SignUp } from 'pages/Auth/SignUp';

import { HomePage } from 'pages/App/Organizer/HomePage';
import { Rescue } from 'pages/App/Organizer/Rescue';
import { ConsultTickets } from 'pages/App/User/ConsultTickets';
import { MyTickets } from 'pages/App/User/MyTickets';
import { BuyTicket } from 'pages/App/User/BuyTicket';
import { Campaign } from 'pages/App/Organizer/Campaign';
import { Buyers } from 'pages/App/Organizer/Buyers';
import { ViewOrganizers } from 'pages/App/Admin/ViewOrganizers';
import { ViewCampaigns } from 'pages/App/Admin/ViewCampaigns';
import { OrganizerDetails } from 'pages/App/Admin/OrganizerDetails';
import { Suport } from 'pages/App/User/Suport';
import { ForgotPassword } from 'pages/Auth/ForgotPassword';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { WhoWeAre } from 'pages/App/User/WhoWeAre';
import { MyProfile } from 'pages/App/Organizer/MyProfile';
import { UserProfile } from 'pages/App/Admin/UserProfile';
import { MyTicketsAffiliate } from 'pages/App/User/MyTicketsAffiliate';
import { Route } from './Route';

export const Routes = (): JSX.Element => {
    const { user } = useAuth();
    // ROTAS DO VISITANTE
    if (!user)
        return (
            <Switch>
                <Route path="/consultar-bilhetes" component={ConsultTickets} />
                <Route path="/meus-bilhetes" component={MyTickets} />
                <Route path="/comprar-bilhete" component={BuyTicket} />
                <Route path="/login" exact component={SignIn} />
                <Route path="/" exact component={SignIn} />
                <Route path="/login" exact component={SignIn} />
                <Route path="/registrar" component={SignUp} />
                <Route path="/campanha/" component={BuyTicket} />
                <Route path="/suporte" exact component={Suport} />
                <Route path="/esqueci-senha" exact component={ForgotPassword} />
                <Route path="/resetar-senha" component={ResetPassword} />
                <Route path="/quem-somos" component={WhoWeAre} />
                <Route path="/meus-bilhetes-associado" component={MyTicketsAffiliate} />
            </Switch>
        );
    // ROTAS DO ORGANIZADOR
    if (user && user.role === 'organizer')
        return (
            <Switch>
                <Route path="/esqueci-senha" exact component={ForgotPassword} />
                <Route path="/meus-dados" exact component={MyProfile} />
                <Route path="/resetar-senha" component={ResetPassword} />
                <Route path="/campanha/" component={BuyTicket} />
                <Route path="/login" exact component={SignIn} />
                <Route path="/" exact isPrivate component={HomePage} />
                <Route path="/registrar" component={SignUp} />
                <Route path="/resgatar" isPrivate component={Rescue} />
                <Route path="/home" isPrivate component={HomePage} />
                <Route path="/criar-campanha" isPrivate component={Campaign} />
                <Route path="/editar-campanha" isPrivate component={Campaign} />
                <Route path="/compradores" isPrivate component={Buyers} />
            </Switch>
        );

    // ROTAS DO ADMINISTRADOR
    if (user && user.role === 'admin')
        return (
            <Switch>
                <Route path="/login" exact component={SignIn} />
                <Route path="/editar-organizador" exact component={UserProfile} />
                <Route path="/registrar" component={SignUp} />
                <Route path="/esqueci-senha" exact component={ForgotPassword} />
                <Route path="/resetar-senha" component={ResetPassword} />
                <Route path="/" exact component={SignIn} />
                <Route path="/home" exact isPrivate component={ViewCampaigns} />
                <Route path="/campanha/" component={BuyTicket} />
                <Route path="/campanhas" exact isPrivate component={ViewCampaigns} />
                <Route path="/organizadores" isPrivate component={ViewOrganizers} />
                <Route path="/editar-campanha" isPrivate component={Campaign} />
                <Route path="/compradores" isPrivate component={Buyers} />
                <Route path="/detalhes-do-organizador" component={OrganizerDetails} />
                <Route path="/consultar-bilhetes" isPrivate component={ConsultTickets} />
                <Route path="/meus-bilhetes" isPrivate component={MyTickets} />
            </Switch>
        );
    return (
        <Switch>
            <Route path="/" exact component={SignIn} />
            <Route path="/registrar" component={SignUp} />
        </Switch>
    );
};
