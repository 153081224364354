import { useCallback, useState } from 'react';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import * as Yup from 'yup';
import { useToast } from 'hooks/toast';
import { forgotPasswordValidator } from 'validators/forgotPassword';
import getValidationErrors from 'utils/getValidationErrors';
import api from 'services/api';
import { useHistory } from 'react-router-dom';
import { Container, ContentInput } from './styles';

export function ForgotPassword(): JSX.Element {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { addSuccess, addError } = useToast();
    const history = useHistory();

    const handleForgotPassword = useCallback(async () => {
        try {
            setIsLoading(true);
            setErrors({});
            await forgotPasswordValidator.validate({ email }, { abortEarly: false });

            try {
                await api.post('auth/start-reset-password', { email });
                addSuccess(`Foi enviado um email para ${email} para recuperar sua senha.`);
            } catch (error) {
                addError('Erro ao recuperar senha');
            } finally {
                setIsLoading(false);
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
                return;
            }

            addError('Erro ao recuperar senha');
        } finally {
            setIsLoading(false);
        }
    }, [email, addError, addSuccess]);

    return (
        <Container>
            <ContentInput>
                <h1>Esqueci a senha</h1>
                <Input
                    name="email"
                    label="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    errors={errors}
                />
                <div className="container-button">
                    <Button color="red" onClick={() => history.goBack()}>
                        Voltar
                    </Button>
                    <Button color="primary" onClick={() => handleForgotPassword()}>
                        {isLoading ? 'Carregando...' : 'Recuperar senha'}
                    </Button>
                </div>
            </ContentInput>
        </Container>
    );
}
