import logoFooter from 'assets/images/img/logo_fundo_verde.png';
import { Container } from './styles';

export function Footer(): JSX.Element {
    return (
        <Container>
            <img src={logoFooter} alt="iRifei" />
            <span>Hoje pode ser seu dia de sorte</span>
        </Container>
    );
}
