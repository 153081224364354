import styled from 'styled-components';

export const Container = styled.footer`
    background-color: var(--primary);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 2rem;
    }

    span {
        font-weight: bold;
        margin-top: 0.5rem;
    }
`;
