import { createContext, useContext, useState } from 'react';

interface CampaignContextData {
    campaignId: string;
    campaignPage: string;
    isFinished: boolean | undefined;
    finishCampaign: () => void;
    campaignIsNotFinished: () => void;
    setIdOfCampaign: (id: string) => void;
    handleSetCampaignPage: (path: string) => void;
}

interface IMenuProviderProps {
    children: JSX.Element | JSX.Element[];
}

const MenuContext = createContext({} as CampaignContextData);

const CampaignProvider = ({ children }: IMenuProviderProps): JSX.Element => {
    const [campaignId, setCampaignId] = useState<string>('');
    const [campaignPage, setCampaignPage] = useState('/home');
    const [isFinished, setIsFinished] = useState<boolean | undefined>();

    const setIdOfCampaign = (id: string) => {
        setCampaignId(id);
    };

    const campaignIsNotFinished = () => setIsFinished(false);

    const finishCampaign = () => setIsFinished(true);

    const handleSetCampaignPage = (path: string) => {
        setCampaignPage(path);
    };

    return (
        <MenuContext.Provider
            value={{
                setIdOfCampaign,
                campaignId,
                handleSetCampaignPage,
                campaignPage,
                isFinished,
                finishCampaign,
                campaignIsNotFinished,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
};

function useCampaign(): CampaignContextData {
    const context = useContext(MenuContext);

    if (!context) {
        throw new Error('useCampaign must be used within a userTypeProvider');
    }

    return context;
}

export { CampaignProvider, useCampaign };
