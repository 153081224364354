export const colors = {
    blueDark: '#222F75',
    blueRegular: '#3860B2',
    blueAqua: '#0077B6',
    blueLight: '#88BBDD',
    purple: '#5551FA',
    red: '#dd1a4b',
    green: '#189399',
    yellow: '#f8d406',
    black: '#000',
    graphite: '#353535',
    white: '#FFF',
    lightGray: '#F2F2F2',
    gray: '#E0E0E0',
    darkGray: '#b0b0b0',
    blackLight: '#414141',
    success: '#0cc033',
    danger: '#D55D45',
    secondary: '#faebd8',
    primary: '#35EC54',
    darkGreen: '#0E9424',
};
