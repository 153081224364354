import styled from 'styled-components';
import { DefaultPage } from '../../_NewDefaultPage';

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 24rem);
`;

export const Title = styled.h1`
    font-size: 2.5rem;
    margin: 2rem 0;

    @media (max-width: 450px) {
        font-size: 2rem;
    }
`;

export const ContainerInput = styled.div`
    width: 30rem;
    margin-top: 2rem;

    @media (max-width: 450px) {
        width: 90%;
    }
`;

export const ContainerButton = styled.div`
    width: 30rem;
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 450px) {
        width: 90%;
    }
`;

export const RadioContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;

    label {
        margin-right: 16px;
        font-size: 1.3rem;
        font-weight: bold;
        margin: 0 10px;
        color: #000;
    }

    input {
        margin-right: 8px;
    }
`;
